import React from 'react';
import { Box } from '@mui/material';
import BottomBarLinks from './BottomBarLinks';


const BottomBar = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px'
      }}
    >
        <BottomBarLinks />
    </Box>
  );
};

export default BottomBar;
