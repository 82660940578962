import React from "react";
import { Box } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import RedditIcon from '@mui/icons-material/Reddit';
import blueskyLogo from '../../assets/blueskyLogo.png';
import colors from "../../config/colors";

const Socials = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <a 
            href="https://x.com/HKBRankings" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
        >
            <Box 
                sx={{
                    width: { xs: 30, sm: 35, md: 40 },
                    height: { xs: 30, sm: 35, md: 40 },
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary200,
                    color: 'black',
                }}
            >
                <XIcon />
            </Box>
        </a>
        <a 
            href="https://bsky.app/profile/harryknowsball.bsky.social" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
        >
            <Box 
                sx={{
                    width: { xs: 30, sm: 35, md: 40 },
                    height: { xs: 30, sm: 35, md: 40 },
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary200,
                }}
            >
                <img 
                    src={blueskyLogo} 
                    alt="Bluesky" 
                    style={{ maxHeight: isSmallScreen ? 30 : 40, objectFit: 'contain' }} 
                />
            </Box>
        </a>
        <a 
            href="https://www.reddit.com/user/HarryKnowsBall/" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
        >
            <Box 
                sx={{
                    width: { xs: 30, sm: 35, md: 40 },
                    height: { xs: 30, sm: 35, md: 40 },
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: colors.primary200,
                    color: 'black',
                }}
            >
                <RedditIcon />
            </Box>
        </a>
    </Box>
    );
};

export default Socials;