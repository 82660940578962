
// Slider

export const calculateSliderValue = (teamOneValue, teamTwoValue) => {
    const totalValue = teamOneValue + teamTwoValue;
    const linearValue = totalValue ? (teamTwoValue / totalValue) * 100 : 50; // Reflects Team 2's share
  
    return denormalizeSliderValue(linearValue);
  };

export const calculateSliderMarkOffset = (teamOneValue, teamTwoValue, acceptanceThreshold) => {
  const totalValue = teamOneValue + teamTwoValue;
  const linearValue = totalValue ? (((totalValue / 2) - (acceptanceThreshold / 2)) / totalValue) * 100 : 50; // Reflects Team 2's share

  const denormalizedSliderValue = denormalizeSliderValue(linearValue);
  const offset = Math.abs(denormalizedSliderValue - 50.0);
  
  // Calculate the two desired return values
  const valueMinusOffset = Math.max(0, 50.0 - offset);
  const valuePlusOffset = Math.min(100, 50.0 + offset);

  // Return these values as an array
  return [valueMinusOffset, valuePlusOffset];
};

const denormalizeSliderValue = (value) => {
  let tempValue = value > 50 ? 100 - value : value;

  const thresholds = [
    { limit: 15, divisor: 2.4 },
    { limit: 25, divisor: 2.2 },
    { limit: 34, divisor: 2.05 },
    { limit: 40.5, divisor: 1.9 },
    { limit: 45, divisor: 1.7 },
    { limit: 46, divisor: 1.5 },
    { limit: 47.2, divisor: 1.35 },
    { limit: 48.5, divisor: 1.2 },
    { limit: 49.5, divisor: 1.1 },
    { limit: 49.85, divisor: 1.05 },
  ];

  for (const { limit, divisor } of thresholds) {
    if (tempValue < limit) {
      tempValue /= divisor;
      break;
    }
  }

  return value > 50 ? 100 - tempValue : tempValue;
};

// Value

export const calculateTeamValue = (teamPlayers, otherTeamPlayers) => {

    const sortedPlayers = [...teamPlayers].sort((a, b) => (b.value + b.excessValue) - (a.value + a.excessValue));
  
    let teamValue = sortedPlayers.reduce((total, player) => total + player.value + player.excessValue, 0);
  
    // Apply depreciation starting from the second player, with increasing percentages
    const valuesWithDepreciation = sortedPlayers.map((player, index) => {
        const depreciationRate = calculateDepreciationRate(index);
        return (player.value + player.excessValue) * (1 - (depreciationRate));
    });
  
    const totalValueWithDepreciation = valuesWithDepreciation.reduce((total, currentValue) => total + currentValue, 0);
  
    const depreciationValue = Math.floor(teamValue - totalValueWithDepreciation);
  
    return { teamValue, depreciationValue };
};

export const calculateRequiredValue = (teamOnePlayers, teamTwoPlayers, teamOneValue, teamTwoValue) => {
  // Helper function to calculate team value with a hypothetical new player
  const calculateTeamValueWithNewPlayer = (players, newPlayerValue) => {
      const allPlayers = [...players, { value: newPlayerValue, excessValue: 0 }];
      const sortedPlayers = [...allPlayers].sort((a, b) => 
          (b.value + b.excessValue) - (a.value + a.excessValue)
      );

      return sortedPlayers.reduce((total, player, index) => {
          const depreciationRate = calculateDepreciationRate(index);
          return total + ((player.value + player.excessValue) * (1 - depreciationRate));
      }, 0);
  };

  // Determine which team needs the player
  const tradeDifference = teamTwoValue - teamOneValue;
  const targetTeamPlayers = tradeDifference > 0 ? teamOnePlayers : teamTwoPlayers;
  const otherTeamValue = tradeDifference > 0 ? teamTwoValue : teamOneValue;

  let left = 0;
  let right = otherTeamValue * 2;
  let bestGuess = Math.floor((left + right) / 2);
  const maxIterations = 12; 

  for (let i = 0; i < maxIterations; i++) {
      const mid = Math.floor((left + right) / 2);
      const newTeamValue = calculateTeamValueWithNewPlayer(targetTeamPlayers, mid);

      if (newTeamValue < otherTeamValue) {
          left = mid + 1;
      } else {
          right = mid - 1;
      }

      bestGuess = mid; // Store the last calculated mid-value
  }

  return bestGuess;
};

const calculateDepreciationRate = (index) => {
  if(index === 0) {
    return 0;
  } else {
    return Math.min(0.78, 0.36 + (index * 0.02))
  }
}

// Copy

export const copyTradeSummary = (teamOnePlayers, teamTwoPlayers, success, tradeDifference) => {
  const teamOneIds = serializePlayerIds(teamOnePlayers);
  const teamTwoIds = serializePlayerIds(teamTwoPlayers);

  // Create formatted player names with emojis based on their value
  const formatPlayer = (player) => {
    let emoji;
    if (player.value >= 3000) {
      emoji = '💪'.repeat(Math.floor(player.value / 3000));
    } else if (player.value >= 1250) {
      emoji = '👍'.repeat(Math.floor(player.value / 1250));
    } else {
      emoji = '😐';
    }
    return `${player.name} ${emoji}`;
  };

  const teamOneNames = teamOnePlayers.map(formatPlayer).join('\n');
  const teamTwoNames = teamTwoPlayers.map(formatPlayer).join('\n');

  // Create trade evaluation text
  const evaluation = success
    ? 'This trade is even!'
    : `This trade ${tradeDifference > 0 ? 'favors Team 2' : 'favors Team 1'} by ${Math.abs(tradeDifference).toLocaleString()} value points.`;

// Construct the trade summary text
const tradeSummary = `Trade Summary:

Team 1 receives:

${teamOneNames}

Team 2 receives:

${teamTwoNames}

${evaluation}

View this trade and others on HarryKnowsBall: https://harryknowsball.com/calculator?teamOne=${teamOneIds}&teamTwo=${teamTwoIds}`;

  // Copy to clipboard
  navigator.clipboard.writeText(tradeSummary);
};

// Misc

export const getMaxPlayerValue = (players) => {
    return Math.max(...players.map(player => player.value + player.excessValue));
};

export const serializePlayerIds = (players) => players.map(player => player.id).join(',');