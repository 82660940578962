import React from 'react';
import { Box, Typography } from '@mui/material';
// Import Link from React Router if you're using it for routing
import { Link } from 'react-router-dom';
import colors from '../../config/colors';

const Title = ({ pageName, extraText, showPickerLink, showRankingsLink }) => {
  const titleStyle = {
    fontSize: { xs: '1.8rem', sm: '1.9rem', md: '2rem'},
    fontFamily: 'Futura, sans-serif',
    fontWeight: 500
  };

  // Style for the smaller text line below the title
  const subtitleStyle = {
    fontSize: { xs: '.9rem', sm: '.9rem', md: '1rem' },
    color: '#656565',
    fontFamily: 'Avenir, Arial, sans-serif',
    textAlign: 'center',
  };

  return (
    <Box sx={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '24px', marginTop: '6px', p: 1  }}>
      <Typography variant="h4" component="h1" sx={titleStyle}>
        {pageName}
      </Typography>
      {extraText != null &&
        (<Typography component="p" sx={{marginTop: '.7rem', ...subtitleStyle}}>
            {extraText}
        </Typography>)}
      {showPickerLink && 
        <Typography component="p" sx={{marginTop: '.7rem', ...subtitleStyle}}>
            Like our site? Help contribute by making <Link to="/picker" style={{ color: colors.primary500, textDecoration: 'none' }}>picks.</Link>
        </Typography>}
      {showRankingsLink &&
        <Typography component="p" sx={{marginTop: '.7rem', ...subtitleStyle}}>
            Your picks inform our <Link to="/rankings" style={{ color: colors.primary500, textDecoration: 'none' }}>rankings</Link>!
        </Typography>}
    </Box>
  );
};

export default Title;
