import FileSaver from 'file-saver';

export const handleDownloadCSV = (players, fileName, useOriginalIndex) => {
    const headers = ['Rank', 'Name', 'Value', 'Age', 'Positions', 'Team', 'Level'];
    const csvRows = [headers.join(',')];

    players.forEach((player, index) => {
      const row = [
        (useOriginalIndex ? player.originalIndex : index) + 1,
        `"${player.name || ''}"`,
        player.value || '',
        player.age || '',
        `"${player.positions?.join(', ') || ''}"`,
        `"${player.team || ''}"`,
        `"${renderLevel(player.level) || ''}"`
      ];
      csvRows.push(row.join(','));
    });

    const csvData = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csvData, fileName);
  };

  const renderLevel = (level) => {
    let levelText = level;
    if(level === 'HIGH_A') {
      levelText = 'A+';
    } else if(level === 'LOW_A') {
      levelText = 'A';
    } else if(level === 'ROOKIE_BALL') {
      levelText = 'ROK';
    }
    return levelText;
  }