import './App.css';
import React from 'react';
import { Navigate } from "react-router-dom";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './containers/LandingPage/LandingPage';
import Rankings from './components/Rankings/Rankings';
import TwoPlayerModal from './components/Picker/PickerModal/TwoPlayerModal';
import Picker from './components/Picker/Picker';
import TradeCalculator from './components/TradeCalculator/TradeCalculator';
import ActivityPage from './components/ActivityPage/ActivityPage';
import Layout from './containers/Layout/Layout';
import FAQ from './components/FAQ/FAQ';
import ContactUs from './components/ContactUs/ContactUs';
import LeagueView from './components/LeagueView/Rankings/LeagueView';
import TeamView from './components/LeagueView/Team/TeamView';
import AvailablePlayersView from './components/LeagueView/AvailablePlayers/AvailablePlayersView';
import PlayerPage from './components/PlayerPage/PlayerPage';
import CookiesPopup from './components/CookiesPopup/CookiesPopup';
import PrivacyPolicyPage from './containers/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfUsePage from './containers/TermsOfUsePage/TermsOfUsePage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import theme from './config/theme';

import { FantasyServiceProvider } from './hooks/league/FantasyServiceContext';
import usePlayerRankings from './hooks/usePlayerRankings';
import useSelectionsCount from './hooks/useSelectionsCount';
import useAllPlayerSelections from './hooks/useAllPlayerSelections';
import useFourPlayerSelection from './hooks/useFourPlayerSelection';
import LeagueInput from './components/LeagueView/LeagueInput/LeagueInput';

function App() {
  const { players, playersError, lastUpdated, topRisers, topFallers } = usePlayerRankings();
  const { selections, selectionsError } = useAllPlayerSelections();
  const { selectionsCount, selectionsCountError } = useSelectionsCount();
  const { selectionPlayers, selectionError, generateSelection, resetDifficulty, difficulty, refreshToggle } = useFourPlayerSelection();

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <FantasyServiceProvider>
          <CookiesPopup />
          <BrowserRouter>
          <ScrollToTop />
            <Routes>
              <Route path="/" element={
                <LandingPage players={players} playersError={playersError} selectionsCount={selectionsCount} selectionsCountError={selectionsCountError} />
                } />
              <Route path="picker" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError} >
                  <Picker players={selectionPlayers} selectionError={selectionError} generateSelection={generateSelection} resetDifficulty={resetDifficulty} difficulty={difficulty} refreshToggle={refreshToggle} />
                </Layout>
              } />
              <Route path="rankings" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <Rankings players={players} error={playersError} lastUpdated={lastUpdated} topRisers={topRisers} topFallers={topFallers} />
                </Layout>
              } />
              <Route path="calculator" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <TradeCalculator playerRankings={players} error={playersError} />
                </Layout>
              } />
              <Route path="activity" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <ActivityPage selections={selections} error={selectionsError} />
                </Layout>
              } />
              <Route path="league" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <LeagueInput />
                </Layout>
              } />
              <Route path="league/:leagueId?/rankings?" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <LeagueView />
                </Layout>
              } />
              <Route path="league/:leagueId/team/:teamId?" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <TeamView />
                </Layout>
              } />
              <Route path="league/:leagueId/available" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <AvailablePlayersView />
                </Layout>
              } />
              <Route path="faq" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <FAQ />
                </Layout>
              } />
              <Route path="contact" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <ContactUs />
                </Layout>
              } />
              <Route path="player/:id" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <PlayerPage players={players} />
                </Layout>
              } />
              <Route path="privacy-policy" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <PrivacyPolicyPage />
                </Layout>
              } />
              <Route path="terms-of-service" element={
                <Layout selectionsCount={selectionsCount} error={selectionsCountError}>
                  <TermsOfUsePage />
                </Layout>
              } />
              <Route
                path="*"
                element={
                  <Navigate to="/" replace /> // Redirect to LandingPage
                }
              />
            </Routes>
            <TwoPlayerModal />
          </BrowserRouter>
        </FantasyServiceProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
