export const getClosestPlayers = (players, targetValue, count = 5, usedPlayers = []) => {
  if (!players?.length || players.length < count) return [];
  
  // Helper function to check if a player is in usedPlayers
  const isPlayerUsed = (player) => {
    return usedPlayers.some(usedPlayer => usedPlayer.id === player.id);
  };

  // Helper function to add player if not used
  const tryAddPlayer = (player) => {
    if (player && !isPlayerUsed(player) && !uniqueClosest.some(p => p.id === player.id)) {
      uniqueClosest.push(player);
      return true;
    }
    return false;
  };
  
  let closestIndex = 0;
  let lastClosest = Number.MAX_SAFE_INTEGER;
  for (let i = 0; i < players.length; i++) {
    const player = players[i];

    if(isPlayerUsed(player)) {
      continue;
    }

    const newDifference = Math.abs(player.value - targetValue);
    if(newDifference > lastClosest) {
      break;
    } else {
      closestIndex = i;
      lastClosest = Math.abs(player.value - targetValue);
    }
  }

  let uniqueClosest = [];

  // Try to add closest player
  tryAddPlayer(players[closestIndex]);

  // Try adjacent players
  if(closestIndex - 1 >= 0) {
    let difference = 1;
    while(closestIndex - difference >= 0 && !tryAddPlayer(players[closestIndex - difference])) {
      difference++;
    }
  }

  if(closestIndex + 1 < players.length) {
    let difference = 1;
    while(closestIndex + difference < players.length && !tryAddPlayer(players[closestIndex + 1])) {
      difference++;
    }
  }

  let breakCondition = 0;
  let leftIndex = 2;
  let rightIndex = 2;
  while(uniqueClosest.length < count && breakCondition < 100) {
    breakCondition++;
  
    let leftPlayer;
    if(closestIndex - leftIndex >= 0) {
      leftPlayer = players[closestIndex - leftIndex];
    } else {
      if (closestIndex + rightIndex < players.length) {
        tryAddPlayer(players[closestIndex + rightIndex]);
      }
      rightIndex++;
      continue;
    }
  
    let rightPlayer;
    if(closestIndex + rightIndex < players.length) {
      rightPlayer = players[closestIndex + rightIndex];
    } else {
      if (closestIndex - leftIndex >= 0) {
        tryAddPlayer(players[closestIndex - leftIndex]);
      }
      leftIndex++;
      continue;
    }

    if(Math.abs(leftPlayer.value - targetValue) > Math.abs(rightPlayer.value - targetValue)) {
      tryAddPlayer(rightPlayer);
      rightIndex++;
    } else {
      tryAddPlayer(leftPlayer);
      leftIndex++;
    }
  }
  
  return uniqueClosest.sort((a, b) => {
    // First compare by value + excessValue (descending)
    const valueComparison = (b.value + b.excessValue) - (a.value + a.excessValue);
    
    // If values are equal, then sort by originalIndex (ascending)
    if (valueComparison === 0) {
        return a.originalIndex - b.originalIndex;
    }
    
    return valueComparison;
});

};

export const getClosestPlayersSimple = (players, targetValue, count = 3, usedPlayers = []) => {
  if (!players?.length || players.length < count) return [];
  
  // Helper function to check if a player is in usedPlayers
  const isPlayerUsed = (player) => {
    return usedPlayers.some(usedPlayer => usedPlayer.id === player.id);
  };

  // Helper function to add player if not used
  const tryAddPlayer = (player) => {
    if (player && !isPlayerUsed(player) && !uniqueClosest.some(p => p.id === player.id)) {
      uniqueClosest.push(player);
      return true;
    }
    return false;
  };
  
  let closestIndex = 0;
  let lastClosest = Number.MAX_SAFE_INTEGER;
  for (let i = 0; i < players.length; i++) {
    const player = players[i];

    if(isPlayerUsed(player)) {
      continue;
    }

    const newDifference = Math.abs(player.value - targetValue);
    if(newDifference > lastClosest) {
      break;
    } else {
      closestIndex = i;
      lastClosest = Math.abs(player.value - targetValue);
    }
  }

  let uniqueClosest = [];

  // Try to add closest player
  tryAddPlayer(players[closestIndex]);

  let breakCondition = 0;
  let leftIndex = 1;
  let rightIndex = 1;
  while(uniqueClosest.length < count && breakCondition < 100) {
    breakCondition++;
  
    let leftPlayer;
    if(closestIndex - leftIndex >= 0) {
      leftPlayer = players[closestIndex - leftIndex];
    } else {
      if (closestIndex + rightIndex < players.length) {
        tryAddPlayer(players[closestIndex + rightIndex]);
      }
      rightIndex++;
      continue;
    }
  
    let rightPlayer;
    if(closestIndex + rightIndex < players.length) {
      rightPlayer = players[closestIndex + rightIndex];
    } else {
      if (closestIndex - leftIndex >= 0) {
        tryAddPlayer(players[closestIndex - leftIndex]);
      }
      leftIndex++;
      continue;
    }

    if(Math.abs(leftPlayer.value - targetValue) > Math.abs(rightPlayer.value - targetValue)) {
      tryAddPlayer(rightPlayer);
      rightIndex++;
    } else {
      tryAddPlayer(leftPlayer);
      leftIndex++;
    }
  }
  
  return uniqueClosest.sort((a, b) => {
    // First compare by value + excessValue (descending)
    const valueComparison = (b.value + b.excessValue) - (a.value + a.excessValue);
    
    // If values are equal, then sort by originalIndex (ascending)
    if (valueComparison === 0) {
        return a.originalIndex - b.originalIndex;
    }
    
    return valueComparison;
});

};