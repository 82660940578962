import React from "react";
import { Typography, Box } from '@mui/material';

const ValueWithLabel = ({ value, label }) => {
  
    return (
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 600, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' } }}>
              {value.toLocaleString()}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, color: 'text.secondary' }}
          >
            {label}
          </Typography>
        </Box>
    );
  };

export default ValueWithLabel;