import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, IconButton, useTheme, Stack, Fade, Modal, Backdrop } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import DownloadIcon from '@mui/icons-material/Download';
import colors from '../../config/colors';
import { handleDownloadCSV } from '../../utils/downloadUtils';
import PlayerRow from './PlayerRow';
import PlayerPreviewCard from '../PlayerPage/PlayerPreviewCard';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  icon: {
    minWidth: { xs: '19px', sm: '24px', md: '27px' },
    width: 'fit-content',
    padding: '0 1px',
    height: { xs: '21px', sm: '26px', md: '29px' },
    borderRadius: '18%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.65rem', sm: '.8rem', md: '1rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
  },
  textSecondary: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
  },
  columnElement: {
    p: { xs: 0.7, sm: 1 },
  },
  italicText: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.7rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    fontStyle: 'italic'
  }
};

const PlayerList = ({ players, isFiltered, isSearched }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handleOpenModal = (player) => {
    setSelectedPlayer(player);
    setOpenPreviewModal(true);
  };
  const handleCloseModal = () => {
    setSelectedPlayer(null);
    setOpenPreviewModal(false);
  };

  const playerSpace = isSmallScreen ? 4 : 7;
  const teamSpace = 1.5;
  const ageSpace = 2;
  const positionSpace = isSmallScreen ? 2.5 : 3;
  const valueSpace = 2;

  useEffect(() => {
    setPage(0);
  }, [isFiltered, isSearched]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const getTierGroups = (players, upperThreshold, lowerThreshold, exponent) => {
    if (!players.length) return [];
  
    const sorted = [...players].sort((a, b) => b.value - a.value);
    const tiers = [];
    let currentTier = [sorted[0]];
    const maxIndex = sorted.length - 1;
  
    for (let i = 1; i < sorted.length; i++) {
      const prev = currentTier[currentTier.length - 1];
      const curr = sorted[i];
  
      const positionRatio = i / maxIndex;
      const easedRatio = 1 - Math.pow(1 - positionRatio, exponent); // Reversed easing
      const threshold = upperThreshold - (upperThreshold - lowerThreshold) * easedRatio;
  
      const percentDiff = Math.abs(prev.value - curr.value) / prev.value * 100;

      const MIN_ABSOLUTE_DROP = 2;
  
      if (percentDiff <= threshold || Math.abs(prev.value - curr.value) < MIN_ABSOLUTE_DROP) {
        currentTier.push(curr);
      } else {
        tiers.push(currentTier);
        currentTier = [curr];
      }
    }
  
    if (currentTier.length > 0) tiers.push(currentTier);
  
    return tiers;
  };
  

  const renderPagination = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', marginTop: 2, marginLeft: { xs: '10px', sm: '12px', md: '14px' }, borderTop: '1px solid', borderColor: colors.greyLight }}>
      <Box sx={{ flex: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <Button
          onClick={() => handleChangePage(Math.max(0, page - 1))}
          disabled={page === 0}
          sx={{ fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}
        >
          Previous
        </Button>
        <Button
          onClick={() =>
            handleChangePage(Math.min(Math.max(Math.ceil(players.length / 50 - 1), 1), page + 1))
          }
          disabled={page >= Math.ceil(players.length / 50) - 1}
          sx={{ fontSize: { xs: '.75rem', sm: '.9rem', md: '1rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}
        >
          Next
        </Button>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton sx={{ padding: 0, ml: 1 }} onClick={() => handleDownloadCSV(players, isFiltered ? 'harryknowsball_filtered_players.csv' : 'harryknowsball_players.csv', true)}>
          <Box
            sx={{
              backgroundColor: colors.primary400,
              color: 'white',
              width: { xs: 30, sm: 34, md: 40 },
              height: { xs: 30, sm: 34, md: 40 },
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DownloadIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.4rem' } }} />
          </Box>
        </IconButton>
      </Box>
    </Box>
  );

  const rowsPerPage = 50;
  const start = page * rowsPerPage;
  const end = Math.min(players.length, start + rowsPerPage);
  const playersForCurrentPage = players.slice(start, end);

  const tierGroups = getTierGroups(players, 5.0, 1.5, 40);
  const visibleTiers = tierGroups.map((tier) => {
    const tierIndexes = tier.map(p => players.findIndex(pl => pl.id === p.id));
    const indexesOnPage = tierIndexes.filter(i => i >= start && i < end);
  
    if (indexesOnPage.length) {
      const tierStart = tierIndexes[0];
      const tierEnd = tierIndexes[tierIndexes.length - 1];
      return {
        topIndex: indexesOnPage[0] - start,
        bottomIndex: indexesOnPage[indexesOnPage.length - 1] - start,
        startsBefore: tierStart < start,
        endsAfter: tierEnd >= end
      };
    }
    return null;
  }).filter(Boolean);

  return (
    <Grid container spacing={{ xs: 1.5, sm: 2 }} style={{ display: 'block' }}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            fontWeight: 'bold',
            marginLeft: 0,
            borderBottom: '1px solid',
            borderColor: colors.greyLight,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            marginBottom: { xs: 1.5, sm: 2 }
          }}
        >
          <Grid container sx={{ paddingLeft: { xs: 1.5, sm: 2 } }}>
            <Grid item xs={playerSpace}>
              <Typography sx={{ ...styles.text, ...styles.columnElement, marginLeft: { xs: '10px', sm: '12px', md: '14px' } }}>Player</Typography>
            </Grid>
            {isSmallScreen && (
              <Grid item sm={teamSpace}><Typography sx={{ ...styles.text, ...styles.columnElement }}>Team</Typography></Grid>
            )}
            {isSmallScreen && (
              <Grid item sm={ageSpace}><Typography sx={{ ...styles.text, ...styles.columnElement }}>Age</Typography></Grid>
            )}
            <Grid item xs={positionSpace}><Typography sx={{ ...styles.text, ...styles.columnElement }}>Positions</Typography></Grid>
            <Grid item xs={valueSpace} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography sx={{ ...styles.text, ...styles.columnElement, marginRight: 1 }}>Value</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Box sx={{ position: 'relative' }}>
        {visibleTiers.map((tier, i) => {
          const { topIndex, bottomIndex } = tier;
          const tierPlayers = playersForCurrentPage.slice(topIndex, bottomIndex + 1);
          return (
            <Box key={`tier-${i}`} sx={{ marginBottom: { xs: 1.5, sm: 1.5, md: 2 }, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: -1,
                  bottom: 0,
                  width: { xs: '2px', sm: '3px', md: '3px' },
                  backgroundColor: colors.primary200,
                  zIndex: 1000
                }}
              />
              {/* Top horizontal line leading into the right border */}
              {!tier.startsBefore && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: -2,
                    right: -1,
                    width: '12px',
                    height: { xs: '2px', sm: '3px', md: '3px' },
                    backgroundColor: colors.primary200,
                    borderTopRightRadius: '4px',
                    zIndex: 1000
                  }}
                />
              )}
              {/* Bottom horizontal line leading into the right border */}
              {!tier.endsAfter && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -2,
                    right: -1,
                    width: '12px',
                    height: { xs: '2px', sm: '3px', md: '3px' },
                    backgroundColor: colors.primary200,
                    borderBottomRightRadius: '4px',
                    zIndex: 1000
                  }}
                />
              )}
              <Stack spacing={{ xs: 1.5, sm: 1.5, md: 2 }} sx={{ paddingLeft: { xs: 1.5, sm: 2 } }}>
                {tierPlayers.map((player, index) => (
                  <Box
                    key={`${player.id}-${index}`}
                    onClick={() => handleOpenModal(player)}
                    sx={{ 
                      position: 'relative', 
                      cursor: 'pointer', }}
                  >
                    <PlayerRow
                      player={player}
                      index={topIndex + index + (page * rowsPerPage)}
                      styles={styles}
                      isSmallScreen={isSmallScreen}
                      playerSpace={playerSpace}
                      teamSpace={teamSpace}
                      ageSpace={ageSpace}
                      positionSpace={positionSpace}
                      valueSpace={valueSpace}
                      isFiltered={isFiltered}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          );
        })}
      </Box>

      {players && players.length > 0 ? (
        renderPagination()
      ) : (
        <Typography sx={{ ...styles.text, fontWeight: 500, padding: 2, textAlign: 'center' }}>
          No players match the selected filter.
        </Typography>
      )}

      <Modal
        open={openPreviewModal}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500, sx: { bgcolor: 'rgba(0, 0, 0, 0.8)' } } }}
      >
        <Fade in={openPreviewModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              width: 'fit-content',
              maxWidth: '90vw',
            }}
          >
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseModal}
              sx={{
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.4)',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.6)',
                },
                mb: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            {selectedPlayer && (
              <PlayerPreviewCard
                key={selectedPlayer.id}
                name={selectedPlayer.name}
                rank={selectedPlayer.originalIndex + 1}
                assetType={selectedPlayer.assetType}
                positions={selectedPlayer.positions || []}
                positionRanks={selectedPlayer.positionRanks || []}
                team={selectedPlayer.team}
                age={selectedPlayer.age}
                fullValueHistory={selectedPlayer.valueHistory30Days || []}
                timeFrame={'1M'}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default PlayerList;
