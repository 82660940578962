import React, { useState } from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area
} from 'recharts';
import { Box, Typography, Card, CardContent, Modal, Fade, Backdrop } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import colors from '../../config/colors';
import DateRangeFilter from './DateRangeFilter';
import QuickInsights from './QuickInsights';
import IconButton from '@mui/material/IconButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PlayerPreviewCard from './PlayerPreviewCard';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  subtext: {
    fontSize: { xs: '.75rem', sm: '.85rem', md: '.95rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    paddingBottom: '6px',
    fontStyle: 'italic'
  },
  heading: {
    fontSize: { xs: '1.3rem', sm: '1.4rem', md: '1.5rem' },
    fontWeight: 'bold', 
    fontFamily: 'Avenir, Arial, sans-serif',
    mb: { xs: '.1rem', sm: '.2rem', md: '.3rem' }
  }
};

const ValueHistoryChart = ({ player, data, formattedEarliestDate, positionRanks }) => {

    const [openPreviewModal, setOpenPreviewModal] = useState(false);

    const handleOpenModal = () => setOpenPreviewModal(true);
    const handleCloseModal = () => setOpenPreviewModal(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [timeScale, setTimeScale] = useState(() => (isSmallScreen ? '1M' : '1 Month'));

    const now = new Date();
    let filteredData = data;

    const daysPerLabel = {
      '1 Year': 365,
      '1Y': 365,
      '6 Months': 183,
      '6M': 183,
      '1 Month': 30,
      '1M': 30,
      '1 Week': 7,
      '1W': 7,
    };
  
    if (timeScale !== 'All') {
      const filterDate = (days) => {
        const pastDate = new Date(now);
        pastDate.setDate(now.getDate() - days);
        return pastDate;
      };
  
      const scales = {
        '1 Year': filterDate(365),
        '1Y': filterDate(365),
        '6 Months': filterDate(183),
        '6M': filterDate(183),
        '1 Month': filterDate(30),
        '1M': filterDate(30),
        '1 Week': filterDate(7),
        '1W': filterDate(7),
      };
  
      const filterByDate = scales[timeScale];
      filteredData = data.filter((entry) => new Date(entry.date) >= filterByDate);
    }

    const handleTimeScaleChange = (value) => {
        if (value && value !== timeScale) {
            setTimeScale(value);
        }
    };

    const getCustomTicks = (data, timeScale) => {
      const dataLength = data.length;

      let ticks;
      if (timeScale === '1W' || timeScale === '1 Week') {
        // Show every day for week view
        ticks = data.map(entry => entry.date);
      } else if (timeScale === '1M' || timeScale === '1 Month') {
        // Show every 3-4 days for month view
        ticks = data.filter((_, index) => index % 4 === 0).map(entry => entry.date);
      } else {
        // For longer periods, calculate evenly distributed ticks
        const min = isSmallScreen ? 4 : 6;
        const numberOfTicks = Math.min(min, dataLength);
        const step = Math.max(1, Math.floor(dataLength / numberOfTicks));
        
        ticks = data
          .filter((_, index) => index % step === 0)
          .map(entry => entry.date);
      }

      return ticks;
    };

    const minValue = Math.min(...filteredData.map((entry) => entry.value));
    const maxValue = Math.max(...filteredData.map((entry) => entry.value));

    const padding = Math.min(100, Math.round(Math.max(20, maxValue / 10)));
    const minY = Math.max(0, Math.floor((minValue - padding) / padding) * padding);
    const maxY = Math.min(10000, Math.ceil((maxValue + padding) / padding) * padding);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div
              style={{
                backgroundColor: '#333',
                border: '1px solid #444',
                padding: isSmallScreen ? '5px' : '10px',
                borderRadius: '10px',
                fontSize: isSmallScreen ? '10px' : '14px',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              }}
            >
              <p
                style={{
                  color: colors.secondary400,
                  margin: '0 0 5px 0',
                  fontWeight: 'bold',
                }}
              >{`${label}`}</p>
              <p style={{ margin: '0' }}>{`Value: ${payload[0].value}`}</p>
              <p style={{ margin: '0' }}>{`Rank: ${payload[0].payload.rank}`}</p>
            </div>
          );
        }
    
        return null;
      };

      const CustomYAxisTick = ({ x, y, payload }) => (
        <text x={x} y={y} dy={6} textAnchor="end" fill="rgba(0, 0, 0, 0.5)">
          <tspan>
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: 12, sm: 13, md: 15 }, fill: 'grey.300', fontWeight: 600, fontFamily: 'Avenir, Arial, sans-serif' }}
              component="tspan"
            >
              {payload.value.toLocaleString()}
            </Typography>
          </tspan>
        </text>
      );

      const CustomXAxisTick = ({ x, y, payload, timeScale }) => {
        const date = new Date(payload.value);
      
        const formatTick = () => {
          if (timeScale === '1 Month' || timeScale === '1M' || timeScale === '1 Week' || timeScale === '1W') {
            return `${date.getUTCMonth() + 1}/${date.getUTCDate()}`; // Month/Day format using UTC
          } else {
            return `${date.toLocaleString('default', { month: 'short', timeZone: 'UTC' })} '${date.getUTCFullYear().toString().slice(-2)}`;
          }
        };
      
        return (
          <text x={x} y={y + 10} textAnchor="start" fill="rgba(0, 0, 0, 0.5)">
            <tspan>
              <Typography
                variant="body2"
                sx={{ fontSize: { xs: 12, sm: 14, md: 16 }, fill: 'grey.300', fontWeight: 500, fontFamily: 'Avenir, Arial, sans-serif' }}
                component="tspan"
              >
                {formatTick()}
              </Typography>
            </tspan>
          </text>
        );
      };

    return (data.length > 0 && (
        <Card
          sx={{ my: 2, border: `1px solid ${colors.secondary500}` }}
        >
          <CardContent sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: 1, borderColor: 'grey.300', mb: { xs: 1, sm: 1.5, md: 2 }, pb: .5 }}>
              <Typography
                variant="h6"
                sx={styles.heading}
              >
                Value History
              </Typography>
              {data.length > 0 ? (
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={styles.subtext}
                >
                  Value tracking for {player.name} began on {formattedEarliestDate}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={styles.subtext}
                  >
                  No value history for {player.name}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: isSmallScreen ? 'space-between' : 'space-between', alignItems: 'center' }}>
              <QuickInsights
                  valueHistoryData={data}
                  value={player.value}
                  rank={player.rank}
                  timeFrame={daysPerLabel[timeScale]}
                />
              <DateRangeFilter
                options={isSmallScreen ? ['1W', '1M', '6M', 'All'] : ['1 Week', '1 Month', '6 Months', 'All']}
                selectedOption={timeScale}
                handleSelect={handleTimeScaleChange}
              />
            </Box>
              <ResponsiveContainer width="100%" height={isSmallScreen ? 210 : 320} style={{ paddingTop: '4px' }} >
                  <AreaChart data={filteredData}>
                      <CartesianGrid stroke="rgba(200, 200, 200, 0.5)" vertical={false} />
                      <XAxis
                        dataKey="date"
                        tick={<CustomXAxisTick timeScale={timeScale} />}
                        ticks={getCustomTicks(filteredData, timeScale)}
                        axisLine={false}
                        tickLine={false}
                        interval={0}
                      />
                      <YAxis
                          domain={[minY, maxY]}
                          tick={<CustomYAxisTick />}
                          tickLine={false}
                          axisLine={false}
                          tickMargin={0}
                          width={isSmallScreen ? 40 : 50}
                      />
                      <Tooltip content={<CustomTooltip />} />
                      <Legend />
                      <defs>
                          <linearGradient id="solidLineGradient" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="10%" stopColor={colors.primary400} stopOpacity={1} />
                              <stop offset="100%" stopColor={colors.primary400} stopOpacity={0.5} />
                          </linearGradient>
                      </defs>
                      <Area
                          type="monotone"
                          dataKey="value"
                          legendType="none"
                          stroke="url(#solidLineGradient)"
                          fill="url(#solidLineGradient)"
                          strokeWidth={4}
                          dot={false}
                          animationDuration={800}
                      />
                  </AreaChart>
              </ResponsiveContainer>
              <IconButton
                aria-label="screenshot"
                size="small"
                sx={{
                  position: 'absolute',
                  top: { xs: 12, sm: 14, md: 16 },
                  right: 12,
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  border: `1px solid ${colors.secondary300}`,
                  boxShadow: 1,
                  '&:hover': {
                    backgroundColor: colors.secondary100,
                  },
                }}
                onClick={handleOpenModal}
              >
                <CameraAltIcon fontSize={ isSmallScreen ? "small" : "medium"} />
              </IconButton>
              <Modal
                open={openPreviewModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500, sx: { bgcolor: 'rgba(0, 0, 0, 0.8)' } } }}
              >
                <Fade in={openPreviewModal}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      outline: 'none',
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'fit-content',
                      maxWidth: '90vw',
                      textAlign: 'center'
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <IconButton
                        aria-label="close"
                        size="small"
                        onClick={handleCloseModal}
                        sx={{
                          color: 'white',
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.6)',
                          },
                          mb: 1,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <PlayerPreviewCard 
                      name={player.name} 
                      rank={player.rank}
                      assetType={player.assetType}
                      positions={player.playerInfo.positions || []}
                      positionRanks={positionRanks}
                      team={player.playerInfo.team}
                      age={player.playerInfo.age}
                      fullValueHistory={player.fullValueHistory} 
                      timeFrame={timeScale}
                      />
                    <Box sx={{ mt: 2 }}>
                      <span style={{ color: 'white', fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 500, fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' } }}>
                        Screenshot, crop and share!
                      </span>
                    </Box>
                  </Box>
                </Fade>
              </Modal>
          </CardContent>
        </Card>
      )
  );
};

export default ValueHistoryChart;
