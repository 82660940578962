import React from 'react';
import { Card, CardContent, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import colors from '../../config/colors';
import logo from '../../assets/Slide1-removebg.png';

const styles = {
  name: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem' },
    fontWeight: 'bold',
  },
  infoHeaderText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
    fontStyle: 'italic',
    color: 'text.secondary',
  },
  infoText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
    color: 'text.secondary',
  },
  dateRange: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
    color: 'text.secondary',
    fontStyle: 'italic'
  },
};

const PlayerPreviewCard = ({ name, rank, assetType, positions, positionRanks, team, age, fullValueHistory, timeFrame }) => {

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const filterDate = (days) => {
    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - days);
    return pastDate;
  };

  const scales = {
    '1 Year': filterDate(365),
    '1Y': filterDate(365),
    '6 Months': filterDate(183),
    '6M': filterDate(183),
    '1 Month': filterDate(30),
    '1M': filterDate(30),
    '1 Week': filterDate(7),
    '1W': filterDate(7),
  };

  const currentDate = new Date();
  const filteredData = fullValueHistory
  .filter((entry, index) => {
    if (!entry) return false; // skip null or undefined entries
    if (timeFrame === 'All') return true;
    const dateToCheck = entry.eventDate ? new Date(entry.eventDate) : new Date(currentDate.getTime() - (index * 24 * 60 * 60 * 1000));
    return dateToCheck >= scales[timeFrame];
  })
  .map((entry) => ({
    date: entry.eventDate,
    value: entry.value,
    rank: entry.rank
  }));

  const firstValue = filteredData.length ? filteredData[0].value : 0;
  const lastValue = filteredData.length ? filteredData[filteredData.length - 1].value : 0;

  const adjustIfTooClose = (a, b) => {
    const absDiff = Math.abs(a - b);
    const diffRatio = absDiff / Math.max(a, b);
  
    // Define minimal absolute difference thresholds
    const minimalAbsoluteDiff = b > 1000 ? 60 : b > 500 ? 20 : b > 300 ? 12 : b > 150 ? 7 : 5;
  
    // Adjust ONLY if BOTH conditions are met
    if (diffRatio < 0.03 && absDiff < minimalAbsoluteDiff && a !== b) {
      const adjustmentPercentage = b > 8000 ? .001 : b > 1000 ? 0.005 : b > 500 ? 0.012 : b > 300 ? 0.014 : b > 150 ? 0.017 : 0.019;
      const adjustment = b * adjustmentPercentage;
  
      return a > b
        ? [Math.min(10000, Math.round(a + adjustment)), Math.round(b - adjustment)]
        : [Math.round(a - adjustment), Math.min(10000, Math.round(b + adjustment))];
    }
  
    return [Math.round(a), Math.round(b)];
  };
  

  const firstRank = filteredData.length ? filteredData[0].rank : 0;

  const minValue = Math.min(...filteredData.map((entry) => entry.value));
  const maxValue = Math.max(...filteredData.map((entry) => entry.value));

  const padding = Math.min(100, Math.round(Math.max(20, lastValue / 10)));
  const minY = Math.max(0, Math.floor((minValue - padding) / padding) * padding);
  const maxY = Math.min(10050, Math.ceil((maxValue + padding) / padding) * padding);

  const [adjustedFirstValue, adjustedLastValue] = adjustIfTooClose(firstValue, lastValue);

  const tickValueMapping = {
    [adjustedFirstValue]: firstValue,
    [adjustedLastValue]: lastValue
  };

  const latestDate = filteredData.length
  ? (() => {
      const [year, month, day] = filteredData[filteredData.length - 1].date.split('-');
      return `${year}/${month}/${day}`;
    })()
  : '';

  const earliestDate = filteredData.length
  ? (() => {
      const [year, month, day] = filteredData[0].date.split('-');
      return `${year}/${month}/${day}`;
    })()
  : '';

  const CustomYAxisTick = ({ x, y, payload, isLastTick }) => {
    const fontSize = isLastTick
      ? isXs
        ? '0.9rem'
        : isSm
        ? '1rem'
        : '1.1rem'
      : isXs
      ? '0.8rem'
      : isSm
      ? '.85rem'
      : '.95rem';
  
    // Use the mapping to display original values
    const displayValue = tickValueMapping[payload.value] !== undefined
      ? tickValueMapping[payload.value]
      : payload.value;
  
    return (
      <text
        x={x}
        y={y}
        dy={4}
        textAnchor="start"
        fill={isLastTick ? colors.primary400 : "#665"}
        fontSize={fontSize}
        fontFamily="Avenir, Arial, sans-serif"
        fontWeight={isLastTick ? 'bold' : 'normal'}
      >
        {displayValue}
      </text>
    );
  };

  return (
    <Card sx={{ width: { xs: 310, sm: 350, md: 400 }, border: `1px solid ${colors.secondary500}`, boxShadow: 3, backgroundColor: 'white' }}>
      <CardContent sx={{ p: 1, position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              sx={{
                backgroundColor: colors.primary400,
                color: 'white',
                minWidth: { xs: 26, sm: 30, md: 34 },
                height: { xs: 28, sm: 32, md: 36 },
                px: .2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                fontWeight: 'bold',
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
                fontFamily: 'Avenir, Arial, sans-serif',
              }}
            >
              {rank}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ ...styles.name, textAlign: 'left', width: '100%', }} component="span">
                {name}
              </Typography>
            </Box>
          </Box>
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{
              height: { xs: 32, sm: 34, md: 38 },
              opacity: 0.8,
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 0.5 }}>
        <Typography sx={{ ...styles.infoText, display: 'flex', alignItems: 'center' }}>
            {firstRank - rank > 0 && (
              <Box component="span" sx={{ color: 'success.main', mr: 0.5 }}>▲</Box>
            )}
            {firstRank - rank < 0 && (
              <Box component="span" sx={{ color: colors.primary400, mr: 0.5 }}>▼</Box>
            )}
            {firstRank - rank === 0 && (
              <Box component="span" sx={{ color: 'text.secondary', mr: 0.5 }}>▲</Box>
            )}
            {Math.abs(firstRank - rank)}
        </Typography>
          {assetType === 'PLAYER' && (
            <Typography sx={{ ...styles.infoText, pl: { xs: 1.9, sm: 2, md: 2.1 } }}>
              {positions && positions.join('/')} ‧ {team} ‧ {age} y.o.
            </Typography>
          )}
        </Box>
          <Box sx={{ width: '100%', height: {xs: 120, sm: 150, md: 180 }, my: 1, position: 'relative' }}>
            <ResponsiveContainer>
            <AreaChart
              data={filteredData}
            >
                <XAxis dataKey="date" hide />
                <YAxis
                  orientation="right"
                  dataKey="value"
                  domain={[minY, maxY]}
                  ticks={[adjustedFirstValue, adjustedLastValue]}
                  tickLine={false}
                  axisLine={false}
                  width={isXs ? (lastValue === 10000 ? 47 : 40) : (lastValue === 10000 ? 55 : 50)}
                  interval={0}
                  tick={(tickProps) => (
                    <CustomYAxisTick
                      {...tickProps}
                      isLastTick={tickProps.payload.value === adjustedLastValue}
                    />
                  )}
                />

                <defs>
                  <linearGradient id="miniChartGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={colors.primary400} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={colors.primary400} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={colors.primary400}
                  fill="url(#miniChartGradient)"
                  strokeWidth={3}
                  dot={false}
                />
                <ReferenceLine
                  y={firstValue}
                  stroke="#665"
                  strokeDasharray="4 2"
                  strokeOpacity={0.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 12,
            left: 12,
            right: 12,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.primary400,
              opacity: 0.9,
              color: 'white',
              fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
              px: 1,
              py: 0.3,
              borderRadius: '6px',
              fontFamily: 'Avenir, Arial, sans-serif',
            }}
          >
            Dynasty Value
          </Box>
          <Typography sx={{ ...styles.infoText, fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' } }}>
            {earliestDate ? `${earliestDate} - ${latestDate}` : 'No value history available'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlayerPreviewCard;