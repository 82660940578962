import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useFantasyLeague } from '../../hooks/league/FantasyServiceContext';

const LeagueViewNavigation = ({ leagueId }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { teams } = useFantasyLeague();;

    const getInitialView = () => {
        if (location.pathname.includes('/rankings')) return 'rankings';
        if (location.pathname.includes(`/team`)) return 'team';
        if (location.pathname.includes(`/available`)) return 'available';
        return 'rankings';
    };

    const [view, setView] = useState(getInitialView);

    const handleViewChange = (selectedView) => {
        if (view === selectedView) return;

        setView(selectedView);

        if (selectedView === 'team') {
            const lastTeamId = localStorage.getItem(`lastTeamId_${leagueId}`);
            const allTeamIds = teams.flat().map(team => team.teamId);

            const validTeamId = lastTeamId && allTeamIds.includes(lastTeamId)
                ? lastTeamId
                : allTeamIds[0]; // Fallback to the first team

            navigate(`/league/${leagueId}/team/${validTeamId}`);
        } else {
            const path = selectedView === 'rankings'
                ? `/league/${leagueId}/rankings`
                : `/league/${leagueId}/available`;

            navigate(path);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Typography
                onClick={() => handleViewChange('rankings')}
                sx={{
                    cursor: 'pointer',
                    color: view === 'rankings' ? 'primary.main' : 'text.secondary',
                    fontWeight: view === 'rankings' ? 'bold' : 'normal',
                    mx: 1,
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontSize: { xs: 14, sm: 16, md: 18 }
                }}
            >
                Power Rankings
            </Typography>
            <Typography sx={{ color: 'text.secondary', mx: 1 }}>•</Typography>
            <Typography
                onClick={() => handleViewChange('team')}
                sx={{
                    cursor: 'pointer',
                    color: view === 'team' ? 'primary.main' : 'text.secondary',
                    fontWeight: view === 'team' ? 'bold' : 'normal',
                    mx: 1,
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontSize: { xs: 14, sm: 16, md: 18 }
                }}
            >
                Rosters
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginX: 1 }}>•</Typography>
            <Typography
                onClick={() => handleViewChange('available')}
                sx={{
                    cursor: 'pointer',
                    color: view === 'available' ? 'primary.main' : 'text.secondary',
                    fontWeight: view === 'available' ? 'bold' : 'normal',
                    mx: 1,
                    fontFamily: 'Avenir, Arial, sans-serif',
                    fontSize: { xs: 14, sm: 16, md: 18 }
                }}
            >
                Available Players
            </Typography>
        </Box>
    );
};

export default LeagueViewNavigation;
