import React from 'react';
import { Grid, Typography, Card, CardContent, Box } from '@mui/material';
import ValueWithLabel from './ValueWithLabel';
import colors from '../../config/colors';

const styles = {
    subtext: {
      fontSize: { xs: '.75rem', sm: '.85rem', md: '.95rem' },
      fontFamily: 'Avenir, Arial, sans-serif',
      paddingBottom: '6px',
      fontStyle: 'italic'
    },
    infotext: {
        fontSize: { xs: '.9rem', sm: '1rem', md: '1.1rem' },
        fontFamily: 'Avenir, Arial, sans-serif',
      },
    heading: {
      fontSize: { xs: '1.4rem', sm: '1.5rem', md: '1.5rem' },
      fontWeight: 'bold', 
      fontFamily: 'Avenir, Arial, sans-serif',
    }
  };

  const ValueText = ({ value, label }) => {
  
    return (
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 600, fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
              {value.toLocaleString()}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, color: 'text.secondary' }}
          >
            {label}
          </Typography>
        </Box>
    );
  };

  const PositionsText = ({ positions, ranks, label }) => {
  
    return (
        <Box sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: .7 }}>
            {positions && positions.map((position) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={position}>
                    <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 600, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }, color: 'black' }}>
                        {position}
                    </Typography>
                    {ranks && ranks[position] && (
                        <Typography
                            sx={{
                                fontSize: '0.75em',
                                color: 'text.secondary',
                                alignSelf: 'flex-end',
                            }}
                            >
                            /{ranks[position]}
                        </Typography>
                    )}
                </Box>
            ))}
            </Box>
          <Typography
            variant="subtitle1"
            sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, color: 'text.secondary' }}
          >
            {label}
          </Typography>
        </Box>
    );
  };

const PlayerInfoCard = ({ player, positionRanks }) => {

  const { name, value, rank, playerInfo, assetType } = player;
  const { age, positions, team, level, batSide, pitchHand, height, weight } = playerInfo;

    const levelMapping = {
        ROOKIE_BALL: "ROK",
        LOW_A: "A",
        HIGH_A: "A+",
        AA: "AA",
        AAA: "AAA",
        MLB: "MLB",
    };

    const columnCount = positions && positions.length > 3 ? 16 : (positions && positions.length > 2 ? 14 : 12);
    const positionColumns = positions && positions.length > 3 ? 7 : (positions && positions.length > 2 ? 5 : 3);

  return (
    <Card
      sx={{ mb: 1, border: `1px solid ${colors.secondary500}`, width: '100%', height: '100%' }}
    >
      <CardContent sx={{ py: 1, pt: assetType === 'PICK' ? 2 : 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: assetType === 'PLAYER' ? 1 : 0, borderColor: 'grey.300', mb: 1 }}>
            <Typography
                variant="h6"
                sx={{ ...styles.heading }}
            >
                {name}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ValueText value={value} label="Value" />
                <Typography
                    variant="body2"
                    sx={{ borderRight: 1, borderColor: 'grey.300', mx: 1, height: '36px' }}
                />
                <ValueText value={rank} label="Rank" />
            </Box>
        </Box>
        <Grid container spacing={1} columns={columnCount}>
            {age && (
            <Grid item xs={3}>
                <ValueWithLabel value={age} label="Age" />
            </Grid>
            )}
            {team && (
            <Grid item xs={3}>
                <ValueWithLabel value={team} label="Team" />
            </Grid>
            )}
            {level && (
            <Grid item xs={3}>
                <ValueWithLabel value={levelMapping[level]} label="Level" />
            </Grid>
            )}
            {positions && (
            <Grid item xs={positionColumns}>
                <PositionsText positions={positions} ranks={positionRanks} label="Positions" />
            </Grid>
            )}
        </Grid>

        <Grid container spacing={1} columns={12}>
            {batSide && (
            <Grid item xs={3}>
                <ValueWithLabel value={batSide} label="Bats" />
            </Grid>
            )}
            {pitchHand && (
            <Grid item xs={3}>
                <ValueWithLabel value={pitchHand} label="Throws" />
            </Grid>
            )}
            {height && (
            <Grid item xs={3}>
                <ValueWithLabel value={height} label="Height" />
            </Grid>
            )}
            {weight && (
            <Grid item xs={3}>
                <ValueWithLabel value={weight} label="Weight" />
            </Grid>
            )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlayerInfoCard;
