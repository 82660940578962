import React, { useState } from 'react';
import { CircularProgress, Alert, Box, Button, Typography, Stack } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { rankFourPlayers } from '../../api/apiService';
import Title from '../Title/Title';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import logo from '../../assets/Picker.png';
import colors from '../../config/colors';
import PlayerPickerCard from './PlayerPickerCard';

// Keyframes for the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fadeOpacity' && prop !== 'delay',
})(({ delay }) => ({
  animation: `${fadeIn} 0.5s ease-out`,
  animationDelay: `${delay}s`,
  animationFillMode: 'forwards',
  opacity: 0, // Ensure it starts invisible
  display: 'flex',
  alignItems: 'center',
  gap: '8px', // Space between the icon and the row
}));

const Picker = ({ players, selectionError, generateSelection, resetDifficulty, difficulty, refreshToggle }) => {

  const [favored1, setFavored1] = useState(null);
  const [favored2, setFavored2] = useState(null);
  const [favored3, setFavored3] = useState(null);
  const [favored4, setFavored4] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const [showStats, setShowStats] = useState(false);
  const statsYear = players.find(player => (player && player.statsYear > 0))?.statsYear || 0;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const payload = { favored1, favored2, favored3, favored4, difficulty };
      await rankFourPlayers(payload);
      resetDifficulty();
      await generateSelection();
    } catch (error) {
      console.error('There was an error submitting the player rankings', error);
    } finally {
      setFavored1(null);
      setFavored2(null);
      setFavored3(null);
      setFavored4(null);
      setSubmitLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await generateSelection();
    } catch (error) {
      console.error('Error refreshing players:', error);
    } finally {
      setFavored1(null);
      setFavored2(null);
      setFavored3(null);
      setFavored4(null);
      setRefreshLoading(false);
    }
  };

  const toggleStatsVisibility = () => {
    setShowStats((prev) => !prev);
  };

  const selectPlayer = (selectedPlayer, index) => {
    [setFavored1, setFavored2, setFavored3, setFavored4].forEach((setFavored, i) => {
      if ([favored1, favored2, favored3, favored4][i] === selectedPlayer) setFavored(null);
    });
    [setFavored4, setFavored3, setFavored2, setFavored1][index](selectedPlayer);
  };

  const primaryDelayBase = 0.2;
  const isSubmitDisabled = ![favored1, favored2, favored3, favored4].every(Boolean);

  return (
    <Box sx={{ p: .6 }}>
      <HeadWrapper title="Dynasty Baseball Picker" description="Choose between players and draft picks to influence our rankings." image={logo} />
      {selectionError && (
        <Alert severity="error" sx={{ marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>
          Error retrieving Picker options. Please check your internet connection or try again later.
        </Alert>
      )}
      <Title
        pageName="Player Picker"
        extraText="Dynasty, keep forever, standard points scoring. Select a unique grade for each player. Each grade can only be used once."
        showPickerLink={false}
        showRankingsLink={true}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
        <Stack spacing={1} sx={{ width: '100%', maxWidth: '600px', mx: 'auto' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, alignItems: 'center', borderBottom: 1, borderColor: colors.greyDark }}>
            {/* Stack Least Valuable and Most Valuable on the left */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pb: 1 }}>
              <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '0.7rem', sm: '.8rem', md: '.9rem' } }}>
                20 = Least Valuable
              </Typography>
              <Typography variant="body1" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '0.7rem', sm: '.8rem', md: '.9rem' } }}>
                80 = Most Valuable
              </Typography>
            </Box>

            {/* Centered button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="text"
                color="primary"
                onClick={toggleStatsVisibility}
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: 600,
                  fontSize: { xs: '0.7rem', sm: '.8rem', md: '.9rem' },
                }}
              >
                {!showStats ? `Show stats` : `Hide stats`} {statsYear !== 0 && `(${statsYear})`}
              </Button>
            </Box>
          </Box>
          <Box sx={{ py: .5 }}>
            {players.map((player, index) => {
              const delay = index * primaryDelayBase;
              return (
                <AnimatedContainer key={`${player?.id || index}-${refreshToggle}`} delay={delay}>
                  <PlayerPickerCard
                    player={player}
                    favored={[favored4?.id, favored3?.id, favored2?.id, favored1?.id]}
                    selectPlayer={selectPlayer}
                    showStats={showStats}
                  />
                </AnimatedContainer>
            )}
            )}
          </Box>
          <Stack spacing={1} sx={{ width: '100%', pb: 2 }}>
            <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%' }} disabled={isSubmitDisabled || submitLoading}>
              {submitLoading ? <CircularProgress size={24} color="inherit" /> : <Typography sx={{ fontSize: isSmallScreen ? '.9rem' : '1rem' }}>Submit Rankings</Typography>}
            </Button>
            <Button variant="outlined" onClick={handleRefresh} sx={{ width: '100%' }} disabled={refreshLoading}>
              {refreshLoading ? <CircularProgress size={24} color="inherit" /> : <Typography sx={{ fontSize: isSmallScreen ? '.9rem' : '1rem' }}>Refresh Players</Typography>}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Picker;
