import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Title from '../Title/Title';
import colors from '../../config/colors';

import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Slide1.png';

// Define a reusable style object for Typography components
const typographyStyles = {
  fontFamily: 'Avenir, Arial, sans-serif',
};

const faqs = {
  Rankings: [
    {
      question: 'How are player values determined?',
      answer: `HarryKnowsBall player rankings are 100% crowdsourced. This means that every player value is determined using selections from users like you. It also means that our rankings stay up to date and flexible in real time. \nOnly the lowest value segment of our player set (< ~50 value points) is determined based on dynasty ownership percentages and not user selections. This section is meant to provide more options in our trade calculator and does not indicate consensus player values.`,
    },
    {
      question: 'What league format is used?',
      answer: `HarryKnowsBall assumes a 14 team league size using standard Fantrax Points scoring.`,
    },
    {
      question: 'Why don\'t I see my players?',
      answer: `HarryKnowsBall aggregates player data from dynasty ownership percentages. If a lesser known player is not included please check back periodically as our player set refreshes weekly.`
    },
    {
      question: 'Why are so many players valued at 10?',
      answer: `In an effort to make your selections as valuable as possible, we designate certain players as Active and others as Inactive. These designations are determined based on rostership percentages and are refreshed weekly. Inactive players are not eligible to be part of player selections and are assigned a default value of 10.`
    }
  ],
  'Trade Calculator': [
    {
      question: 'How should I use the Trade Calculator?',
      answer: `The Trade Calculator should be used as a tool for evaluating dynasty trades. It is meant to serve as a single data point rather than the entire evaluation. As always, know your league mates and understand how your unique league and scoring settings might sway value one way or another.`,
    },
    {
      question: 'What is package adjustment?',
      answer: `Package adjustment serves to penalize using multiple lower value players to acquire a single high value player. It puts a premium on higher value players.`,
    }
  ],
  'League Breakdown': [
    {
      question: 'How are power rankings determined?',
      answer: 'League power rankings are determined by aggregating our crowdsourced player and pick values for each team in a given league. We rank teams by total value, and create tiers based on value gaps. We also break down the value distribution by position to identify which areas are the strongest, and which areas are the weakest for each team.',
      additionalDetails: (
        <>
          <br />
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Entire Roster</Typography>
          <ul>
            <li><Typography sx={typographyStyles}>Sums entire team value to create rankings and tiers</Typography></li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Farm System</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Sums value of all minor leaguers to create rankings and tiers</Typography>
            </li>
          </ul>
          <Typography  sx={typographyStyles} variant="subtitle1" component="h3">Starting Lineup</Typography>
          <ul>
            <li>
              <Typography sx={typographyStyles}>Creates the best starting lineup from your available MLB players and sums the lineup value to create rankings and tiers</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Uses a standard [C, 1B, 2B, 3B, SS, 3 OF, 5 SP, 3 RP, 2 UT] lineup. Specific league lineup settings will be integrated in the future</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>For players with multiple position eligibility, they will be used at the position to maximize total lineup value</Typography>
            </li>
            <li>
              <Typography sx={typographyStyles}>Two way players can be double counted, regular multi-position players will not be double counted</Typography>
            </li>
          </ul>
        </>
      ),
    },
    {
      question: 'Will there be future support for other league platforms?',
      answer: 'League Breakdown only supports Fantrax and Yahoo at this time. However, there are plans to expand to other platforms in the near future. Keep an eye out for updates on this front.'
    },
  ],
  Picker: [
    {
      question: 'What is the Player Picker?',
      answer: 'The Player Picker helps us understand how users value different players. When you make a player selection, the involved players either gain value or lose value in our rankings based on your selection order. If you like our site, the best way to help contribute is by making player selections using this tool.'
    },
    {
      question: 'How does the 20 - 80 scale work?',
      answer: 'The 20 - 80 scale is based on the popular prospect grading system that assigns player values on a numeric scale: 20 being the worst and 80 the best. Similarly, when using the Player Picker, you will assign each of the four players a unique dynasty value grade between 20 and 80, with 20 being the worst and 80 the best among the group!'
    }
  ],
  General: [
    {
      question: 'What\'s next?',
      answer: `Our team is continuously working on enhancements and will release new features on an ongoing basis. Some priorities include team and league evaluations, including more interactive injury and prospect filters, and integrating other analytics tools. Please contact us if you have any features you would like to see!`,
    },
    {
      question: 'Who is Harry?',
      answer: `Harry of HarryKnowsBall is indeed a dynasty baseball loving software engineer. He spends his free time watching his beloved Padres and drinking IPAs, win or lose. He has a dog named after Fernando Tatis Jr. and a strong distaste for Jon Heyman and Ken Rosenthal.`,
    },
    {
      question: 'Contact Info',
      answer: `To contact the HKB team please email us at harryknowsballteam@gmail.com`,
    }
  ],
};

const FAQSection = ({ title, faqs }) => (
  <>
    <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }}>{title}</Typography>
    <List>
      {faqs.map((faq, index) => (
        <Accordion 
          sx={{
            mb: 2,
            boxShadow: 'none', 
            borderBottom: '1px solid rgba(0, 0, 0, .125)', 
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 'auto',
            },
            '& .MuiAccordionSummary-root': {
              borderBottom: 0,
            },
            '& .MuiAccordionDetails-root': {
              borderTop: 0,
            }
          }} 
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
            <Typography sx={{ ...typographyStyles, fontWeight: 500 }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={typographyStyles}>
              {faq.answer}
            </Typography>
              {faq.additionalDetails}
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  </>
);

const FAQ = () => {
  return (
    <>
      <HeadWrapper 
        title="Frequently Asked Questions" 
        description="Frequently asked questions for HarryKnowsBall." 
        image={logo} 
      />
      <Title pageName="FAQ" showPickerLink={true} showRankingsLink={false}  />
      <Container maxWidth="sm" sx={{ py: 0, display: 'flex', flexDirection: 'column', gap: 0, p: 2 }}>
        {Object.entries(faqs).map(([title, faqItems]) => (
          <FAQSection key={title} title={title} faqs={faqItems} />
        ))}
      </Container>
    </>
  );
};

export default FAQ;
