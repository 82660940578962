import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Card,
  CardContent,
  useTheme,
  CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MiniPlayerChart from '../Team/MiniPlayerChart'; // Import the MiniPlayerChart component
import colors from '../../../config/colors';
import PositionBox from '../../PositionBox/PositionBox';
import { useFantasyLeague } from '../../../hooks/league/FantasyServiceContext';
import HeadWrapper from '../../HeadWrapper/HeadWrapper';
import Title from '../../Title/Title';
import logo from '../../../assets/fullNameLogo.png';
import LeagueViewNavigation from '../LeagueViewNavigation';
import DownloadIcon from '@mui/icons-material/Download';
import { handleDownloadCSV } from '../../../utils/downloadUtils';

const styles = {
  icon: {
    width: { xs: '22px', sm: '25px', md: '28px' },
    height: { xs: '22px', sm: '25px', md: '28px' },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
    p: { xs: 0.7, sm: 1 },
  },
  textSecondary: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    p: 1,
  },
};

const AvailablePlayersView = () => {
    const minimumPlayerCount = 10;
    const [playersToDisplayCount, setPlayersToDisplayCount] = useState(minimumPlayerCount);

    const { leagueId } = useParams();
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { unownedPlayers: players, setLeague, teams, loading, activeService } = useFantasyLeague();

    useEffect(() => {
      const initializeLeague = async () => {
        if (teams.length === 0) {
          const success = await setLeague(leagueId);
          if (!success) {
            navigate(`/league/${leagueId}/rankings`, { replace: true });
            return;
          }
        }
      };
  
      initializeLeague();
    }, [leagueId, setLeague, teams.length, navigate]);

  useEffect(() => {
    setPlayersToDisplayCount(minimumPlayerCount);
  }, [players]);

  const playersToDisplay = players.slice(0, playersToDisplayCount);

  if (!players) {
    return null;
  }

  const getHeadWrapperTitle = () => {
    return `Personalized ${activeService} League Available Players`;
  };

  const getDescription = () => {
    return `Enter your ${activeService} League ID and see real-time dynasty fantasy baseball power rankings, roster breakdowns, and available players based on crowdsourced player values.`;
  };

  return (
    <Box
        sx={{
        display: 'flex',
        justifyContent: 'center',
        pb: 4
        }}
    >
        <Box
            sx={{
                maxWidth: '800px',
                width: '100%',
            }}
        >
            <HeadWrapper
                title={getHeadWrapperTitle()}
                description={getDescription()}
                image={logo}
            />
            <Title
                pageName="League Breakdown"
                extraText="View power rankings and roster breakdowns for your league! All rankings are calculated using our crowdsourced player and pick values."
                showPickerLink={true}
                showRankingsLink={false}
            />
            <LeagueViewNavigation leagueId={leagueId} />
            <Box sx={{ padding: { xs: .5, sm: 1 }, margin: '0 auto' }}>
                <Card
                    sx={{ my: 2, border: `1px solid ${colors.secondary500}`, paddingBottom: 0 }}>
                <CardContent
                    sx={{
                        '&:last-child': {
                            paddingBottom: 0,
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Avenir, Arial, sans-serif',
                                    fontWeight: 'bold',
                                    paddingLeft: '6px',
                                    fontSize: { xs: 18, sm: 22, md: 28 },
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                                >
                                Available Players
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Avenir, Arial, sans-serif',
                                    fontWeight: 'normal',
                                    fontSize: { xs: 12, sm: 14, md: 16 },
                                    color: colors.grey500,
                                    paddingLeft: '6px',
                                    py: .5
                                }}
                            >
                                {players.length > 0 ? `All unowned HKB players from your league!` : (!loading && 'Looks like your league owns every active HKB player. Impressive stuff!')}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                onClick={() => handleDownloadCSV(players, 'harryknowsball_league_available.csv', false)}
                                aria-label="Download available players list"
                                sx={{
                                    padding: 0,
                                    ml: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                    backgroundColor: colors.primary400,
                                    color: 'white',
                                    width: { xs: 30, sm: 34, md: 40 }, // Circle size
                                    height: { xs: 30, sm: 34, md: 40 },
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    }}
                                >
                                    <DownloadIcon sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.4rem' } }} />
                                </Box>
                            </IconButton>
                        </Box>
                    </Box>
                    {loading && 
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 2
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    <Grid
                        container
                        spacing={{ xs: 1.5, sm: 2 }}
                        style={{ display: 'block' }}
                        sx={{ mt: { xs: .75, sm: 1.25, md: 1.75 } }}
                    >
                    {playersToDisplay.map((player, index) => (
                        <Grid
                        item
                        xs={12}
                        key={`${player.id}-${index}`}
                        sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <Box sx={styles.icon}>{index + 1}</Box>
                            <Paper
                            sx={{
                                ...styles.row,
                                height: '100%',
                                width: '100%',
                            }}
                            >
                            <Grid container>
                                <Grid item xs={5} sm={3.5} 
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <Typography
                                    component={Link} // Use the Link component as the base
                                    to={`/player/${player.id}`}
                                    sx={{
                                    ...styles.text,
                                    marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                                    }}
                                >
                                    {player.name}
                                </Typography>
                                </Grid>
                                {!isSmallScreen && (<Grid item xs={3} sm={2}
                                    sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {player.assetType === 'PLAYER' && (
                                    <Typography
                                        sx={{
                                        ...styles.textSecondary,
                                        marginRight: 1,
                                        }}
                                    >
                                        {player.age}y
                                    </Typography>
                                    )}
                                </Grid>)}
                                <Grid item xs={3.5}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                }}>
                                    {player.assetType === 'PLAYER' ? (
                                        <Typography
                                        sx={{
                                            ...styles.textSecondary,
                                            marginRight: 0,
                                        }}
                                        >
                                        {player.positions && player.positions.map((position, index) => <PositionBox key={`${player.id}-${position}`} position={position} rank={player.positionRanks[position]} />)}
                                        </Typography>
                                    ) : (
                                        <Typography
                                        sx={{
                                            ...styles.textSecondary,
                                            marginRight: 1,
                                        }}
                                        >
                                        <PositionBox key={index} position="PICK" />
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={3.5} // Adjust width based on your layout needs
                                    sm={3}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MiniPlayerChart valueHistory={player.valueHistory30Days} defaultValue={100} />
                                    <Typography
                                        sx={{
                                        ...styles.text,
                                        marginRight: 1,
                                        }}
                                    >
                                        {player.value && player.value.toLocaleString()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            </Paper>
                        </Box>
                        </Grid>
                    ))}
                    </Grid>
                    {players.length > minimumPlayerCount ? (
                        <Box
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            py: 1,
                            }}
                        >
                            <IconButton
                                onClick={() => 
                                    playersToDisplayCount >= players.length 
                                        ? setPlayersToDisplayCount(minimumPlayerCount)
                                        : setPlayersToDisplayCount((prevCount) =>
                                            prevCount + minimumPlayerCount >= players.length 
                                                ? players.length 
                                                : prevCount + minimumPlayerCount
                                        )
                                }
                                sx={{
                                    color: colors.primary400,
                                    '&:hover': { color: colors.primary300 },
                                }}
                                aria-label={playersToDisplayCount >= players.length ? "Show less players" : "Show more players"}
                            >
                                {playersToDisplayCount >= players.length ? (
                                    <KeyboardArrowUpIcon
                                        fontSize="large"
                                    />
                                ) : (
                                    <KeyboardArrowDownIcon
                                        fontSize="large"
                                    />
                                )}
                            </IconButton>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            py: 1.5,
                            }}
                        ></Box>
                    )}
                </CardContent>
                </Card>
            </Box>
        </Box>
    </Box>
  );
};

export default AvailablePlayersView;
