import React, { useState } from 'react';
import { Alert, Box, CircularProgress } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import logo from '../../assets/fullNameLogo.png';

import HeadWrapper from '../HeadWrapper/HeadWrapper';
import Title from '../Title/Title';
import SelectionList from './SelectionList';
import colors from '../../config/colors';

const ActivityPage = ({ selections, error }) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <HeadWrapper
        title="Activity Feed"
        description="Crowdsourced dynasty baseball rankings. View the community's most recent selections here!"
        image={logo}
      />
      <Title
        pageName="Activity Feed"
        extraText="Take a look at the most recent player selections across the community!"
        showPickerLink={false}
        showRankingsLink={true}
      />
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Error retrieving previous selections. Please check your internet connection or try again later.
        </Alert>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', marginBottom: 2 }}>
        {selections && selections.length > 0 ? (
          <SelectionList
            selections={selections}
            visibleCount={visibleCount}
            setVisibleCount={setVisibleCount}
            isSmallScreen={isSmallScreen}
          />
        ) : (
          !error && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            >
              <CircularProgress sx={{ color: colors.primary400 }} />
            </Box>
          )
        )}
      </Box>
    </>
  );
};

export default ActivityPage;
