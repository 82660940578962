import React from 'react';
import { Paper, Grid, Typography, Collapse, Button, Box, Skeleton } from '@mui/material';
import colors from '../../config/colors';
import { isPositionPlayer, isPitcher } from '../../utils/statsUtils';

const statsText = {
  fontFamily: 'Avenir, Arial, sans-serif',
  color: 'text.secondary',
  fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
};

const PlayerPickerCard = ({ player, favored, selectPlayer, showStats }) => {
  if (!player) {
    return (
      <Paper elevation={1} sx={{ py: 1.8, px: 2, border: `1px solid ${colors.greyDark}`, backgroundColor: 'white', my: 1, width: '100%' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
            {[20, 40, 60, 80].map((value, index) => (
              <Skeleton key={index} variant="rectangular" width={60} height={36} sx={{ borderRadius: '10%' }} />
            ))}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const buttonValues = [20, 40, 60, 80];

  return (
    <Paper elevation={1} sx={{ py: 1.8, px: 2, border: `1px solid ${colors.greyDark}`, backgroundColor: 'white', my: { xs: .5, sm: .7, md: .9 }, width: '100%' }}>
      <Grid container sx={{ mb: 1, alignItems: 'center' }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.9rem', sm: '1rem', md: '1.1rem' } }}>{player.name}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
          <Typography variant="body1" sx={statsText} color="text.secondary">
            {player.assetType === 'PICK' ? player.assetType : `${player.positions.join(', ')} ‧ ${player.team} ‧ ${player.age}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={showStats} timeout="auto" unmountOnExit>
            <Grid container direction="column" spacing={0.5}>
                {isPositionPlayer(player.positions) && (player.hitterStats ? (
                  <Grid item container justifyContent="space-between" sx={{ mt: .5, gap: .5 }}>
                    <Typography
                      variant="body2"
                      sx={{ ...statsText }}
                    >
                      {player.activeLevels}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ ...statsText, borderRight: 1, borderColor: 'grey.300' }}
                    >
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.hitterStats.atBats} AB
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.hitterStats.avg && (player.hitterStats.avg < 1 ? player.hitterStats.avg.toFixed(3).substring(1) : player.hitterStats.avg.toFixed(3))} AVG
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.hitterStats.ops && (player.hitterStats.ops < 1 ? player.hitterStats.ops.toFixed(3).substring(1) : player.hitterStats.ops.toFixed(3))} OPS 
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.hitterStats.homeRuns} HR
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.hitterStats.stolenBases} SB
                    </Typography>
                  </Grid>
                ) : (
                  !player.pitcherStats && (
                    <Grid item container justifyContent="center" sx={{ mt: .5 }}>
                      <Typography
                        variant="body2"
                        sx={statsText}
                      >
                        No stats available.
                      </Typography>
                    </Grid>
                  )
                ))}

                {isPitcher(player.positions) && (player.pitcherStats ? (
                  <Grid item container justifyContent="space-between" sx={{ mt: .5, gap: .5 }}>
                    <Typography
                      variant="body2"
                      sx={{ ...statsText }}
                    >
                      {player.activeLevels}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ ...statsText, borderRight: 1, borderColor: 'grey.300' }}
                    >
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.pitcherStats.inningsPitched.toFixed(1)} IP 
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.pitcherStats.strikeOuts} SO 
                    </Typography>
                    {player.pitcherStats.gamesStarted / player.pitcherStats.gamesPlayed < .2 ? (
                      <Typography
                        variant="body2"
                        sx={statsText}
                      >
                        {player.pitcherStats.saves} SV
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={statsText}
                      >
                        {player.pitcherStats.wins}-{player.pitcherStats.losses} WL
                      </Typography>
                    )}
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.pitcherStats.era && player.pitcherStats.era.toFixed(2)} ERA
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={statsText}
                    >
                      {player.pitcherStats.whip && player.pitcherStats.whip.toFixed(2)} WHIP
                    </Typography>
                  </Grid>
                ) : (
                  !player.hitterStats && (
                    <Grid item container justifyContent="center" sx={{ mt: .5 }}>
                      <Typography
                        variant="body2"
                        sx={statsText}
                      >
                        No stats available.
                      </Typography>
                    </Grid>
                  )
                ))}
              </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', pt: 0.5 }}>
        {buttonValues.map((value, index) => (
          <Button
            key={`${player.id}-${value}`}
            variant={favored[index] && favored[index] === player.id ? 'contained' : 'outlined'}
            onClick={() => selectPlayer(player, index)}
            sx={{ flexGrow: 1, mx: 0.2, fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' } }}
          >
            {value}
          </Button>
        ))}
      </Box>
    </Paper>
  );
};

export default PlayerPickerCard;
