import React from 'react';
import { Box } from '@mui/material';
import Title from '../../Title/Title';
import HeadWrapper from '../../HeadWrapper/HeadWrapper';
import FantraxLeagueInput from './FantraxLeagueInput';
import logo from '../../../assets/fullNameLogo.png';
import YahooLeagueInput from './YahooLeagueInput';
import { useFantasyLeague } from '../../../hooks/league/FantasyServiceContext';

const LeagueInput = () => {

  const {
    setLeague
  } = useFantasyLeague();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
        }}
      >
        <HeadWrapper
          title="Dynasty Baseball League Power Rankings"
          description="Enter your Fantrax or Yahoo League ID and see real-time dynasty fantasy baseball power rankings, based on crowdsourced player values."
          image={logo}
        />
        <Title
          pageName="League Breakdown"
          extraText="View power rankings and roster breakdowns for your league! All rankings are calculated using our crowdsourced player and pick values."
          showPickerLink={true}
          showRankingsLink={false}
        />
        <Box gap={2} display="flex" flexDirection="column">
          <FantraxLeagueInput setLeague={setLeague} />
          <YahooLeagueInput setLeague={setLeague} />
        </Box>
      </Box>
    </Box>
  );
};

export default LeagueInput;