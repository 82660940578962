import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import colors from '../../config/colors';
import Socials from './Socials';

const styles = {
  text: {
    fontSize: {
      xs: '.9rem',
      sm: '1rem',
      md: '1.2rem',
    },
    fontFamily: 'Avenir, Arial, sans-serif',
    color: 'grey.700',
    fontWeight: 700,
    textDecoration: 'none',
  },
  legalText: {
    fontSize: {
      xs: '0.65rem',
      sm: '0.8rem',
      md: '1rem',
    },
    fontFamily: 'Avenir, Arial, sans-serif',
  }
};

const BottomBarLinks = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: colors.primary100,
        padding: '15px 0',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        borderTop: 3,
        borderColor: colors.primary200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: .5,
      }}
    >
      {/* First Row */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 1.5, sm: 2.5, md: 4 },
          flexWrap: 'wrap',
        }}
      >
        <Socials />
        <Typography>‧</Typography>
        <Typography color="text.secondary" component={Link} to="/faq" sx={styles.text}>
          FAQ
        </Typography>
        <Typography>‧</Typography>
        <Typography color="text.secondary" component={Link} to="/contact" sx={styles.text}>
          Contact Us
        </Typography>
        <Typography>‧</Typography>
        <Typography color="text.secondary" component={Link} to="/privacy-policy" sx={styles.text}>
          Privacy Policy
        </Typography>
        <Typography>‧</Typography>
        <Typography color="text.secondary" component={Link} to="/terms-of-service" sx={styles.text}>
          Terms of Use
        </Typography>
      </Box>

      {/* Second Row (Placeholder) */}
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography 
          color="text.secondary"
          sx={styles.legalText}
        >
          © {new Date().getFullYear()} HarryKnowsBall.com. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default BottomBarLinks;
