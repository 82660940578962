import React from 'react';
import { Paper, Box, Typography, Slider } from '@mui/material';
import { calculateSliderValue, calculateSliderMarkOffset } from '../../utils/tradeUtils';
import TradeDifferenceIndicator from './TradeDifferenceIndicator';

const styles = {
  headerText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
    fontWeight: 600
  },
  bodyText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
    fontWeight: 500
  },
};

const TradeValueSlider = ({ 
  teamOneValue, 
  teamTwoValue, 
  tradeDifference,
  acceptanceThreshold,
  success
}) => {

  const sliderMarks = acceptanceThreshold ? (() => {
    const [valueMinusOffset, valuePlusOffset] = calculateSliderMarkOffset(teamOneValue, teamTwoValue, acceptanceThreshold);
    return [
      {
        value: valueMinusOffset,
        label: '',
      },
      {
        value: valuePlusOffset,
        label: '',
      },
    ];
  })() : [];

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        py: 1.5,
        display: 'flex',
        borderRadius: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...(acceptanceThreshold !== null && {
          bgcolor: success ? '#EDF7ED' : '#FDEDED',
          border: '2px solid',
          borderColor: success ? 'success.light' : 'error.light',
        })
      }}
    >
      <Slider
        value={calculateSliderValue(teamOneValue, teamTwoValue)}
        aria-labelledby="trade-balance-slider"
        marks={sliderMarks}
        sx={{
          maxWidth: '85%',
          '& .MuiSlider-track, & .MuiSlider-rail': {
            color: 'grey.400',
          },
          '& .MuiSlider-rail': {
            opacity: 1.0,
            height: '6px',
          },
          ...(acceptanceThreshold !== null && {
            '& .MuiSlider-thumb': {
              color: success ? 'success.main' : 'error.main',
            },
            '& .MuiSlider-track, & .MuiSlider-rail': {
              color: success ? 'success.light' : 'error.light',
            },
            '& .MuiSlider-rail': {
              opacity: 1.0,
              height: '6px',
            },
            '& .MuiSlider-mark': {
              backgroundColor: success ? 'success.light' : 'error.light',
              width: '3px',
              height: '30px',
              borderRadius: 2,
            },
          }),
        }}
        disabled
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box sx={{ textAlign: 'left', px: 3 }}>
          <Typography sx={styles.headerText}>Team 1</Typography>
          <Typography sx={styles.bodyText}>{teamOneValue.toLocaleString()}</Typography>
        </Box>
        {acceptanceThreshold !== null && (
          <TradeDifferenceIndicator tradeDifference={tradeDifference} success={success} />
        )}
        <Box sx={{ textAlign: 'right', px: 3 }}>
          <Typography sx={styles.headerText}>Team 2</Typography>
          <Typography sx={styles.bodyText}>{teamTwoValue.toLocaleString()}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TradeValueSlider;