import React from 'react';
import { Box, Button } from '@mui/material';
import colors from '../../config/colors';

const DateRangeFilter = ({ options, selectedOption, handleSelect }) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {options.map((option) => (
                <Button
                    key={option}
                    variant={option === selectedOption ? 'contained' : 'outlined'}
                    color={option === selectedOption ? 'secondary' : 'inherit'}
                    onClick={() => handleSelect(option)}
                    sx={{
                        mx: { xs: .3, sm: .5, md: 1 },
                        fontFamily: 'Avenir, Arial, sans-serif',
                        fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' },
                        fontWeight: option === selectedOption ? 'bold' : 'normal',
                        textTransform: 'none',
                        borderColor: option !== selectedOption ? colors.secondary500 : undefined,
                        minWidth: { xs: '44px', sm: '56px', md: '64px' },
                        '&:hover': {
                            borderColor: option !== selectedOption ? 'secondary.dark' : undefined, // Optional hover effect
                        },
                    }}
                >
                    {option}
                </Button>
            ))}
        </Box>
    );
};

export default DateRangeFilter;
