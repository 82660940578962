import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Collapse,
  IconButton,
  Paper
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import { useFantraxLeagueBreakdown } from '../../../hooks/league/FantraxLeagueContext';
import { LEAGUE_SOURCES } from '../../../hooks/league/FantasyServiceContext';
import colors from '../../../config/colors';

const FantraxLeagueInput = ({ setLeague }) => {

  const navigate = useNavigate();
  const [instructionsExpanded, setInstructionsExpanded] = useState(false);
  const [leagueId, setLeagueId] = useState('');
  const [lastUsedLeagueIds, setLastUsedLeagueIds] = useState([]);
  const [copiedLeagueId, setCopiedLeagueId] = useState(null);

  const {
    loading,
    badRequestError,
    badRequestLeagueId,
  } = useFantraxLeagueBreakdown();

  const handleSubmit = async () => {
    const success = await setLeague(leagueId, LEAGUE_SOURCES.FANTRAX);
    
    if (success) {
      const updatedLeagueIds = [leagueId, ...lastUsedLeagueIds.filter((id) => id !== leagueId)].slice(0, 3);
      localStorage.setItem('fantraxLastUsedLeagueIds', JSON.stringify(updatedLeagueIds));
      setLastUsedLeagueIds(updatedLeagueIds);
      navigate(`/league/${leagueId}/rankings`);
    }
  };

  const handleCopyLeagueId = (id) => {
    navigator.clipboard.writeText(id);
    setCopiedLeagueId(id);

    // Reset the icon to the default state after 2 seconds
    setTimeout(() => setCopiedLeagueId(null), 3000);
  };

  useEffect(() => {
    const savedLeagueIds = JSON.parse(localStorage.getItem('fantraxLastUsedLeagueIds')) || [];
    setLastUsedLeagueIds(savedLeagueIds);
  }, []);

  const displayInstructions = () => (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 1,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        width: '90%',
        margin: '0 auto',
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' } }}>
        Navigate to your Fantrax league on an internet browser. Look at the URL in your browser's address bar. The League ID is the part after <code>league/</code>.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        For example, in the URL <strong>https://www.fantrax.com/fantasy/league/nfxjaho3p8ys6gqh/home</strong>, the League ID is <strong>nfxjaho3p8ys6gqh</strong>.
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Paper sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 1, boxShadow: 2 }}>
        <Stack spacing={2}>
          <Typography
            sx={{
                fontFamily: 'Futura, Arial, sans-serif',
                fontWeight: 600,
                paddingLeft: '6px',
                fontSize: { xs: 20, sm: 22, md: 24 },
                display: 'flex',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
                color: colors.primary400
            }}
          >
            Fantrax
          </Typography>
          <TextField
            id="league-id-input"
            name="leagueId"
            label="Fantrax League ID"
            variant="outlined"
            value={leagueId}
            onChange={(e) => setLeagueId(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !leagueId || leagueId.length === 0}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </Button>
          {lastUsedLeagueIds.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                <IconButton
                  size="small"
                  disabled
                  sx={{ marginLeft: '4px',
                    padding: '4px',
                    color: 'grey',
                    '& .MuiSvgIcon-root': {
                      color: 'grey',
                    },
                  }}
                >
                  <HistoryIcon fontSize="small" />
                </IconButton>
                {lastUsedLeagueIds.map((id, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '4px 8px',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCopyLeagueId(id)}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Avenir, Arial, sans-serif',
                        whiteSpace: 'nowrap',
                        fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }
                      }}
                    >
                      {id}
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        padding: '4px',
                        transition: 'transform 0.4s ease, opacity 0.4s ease',
                        transform: copiedLeagueId === id ? 'scale(1.2)' : 'scale(1)',
                        opacity: copiedLeagueId === id ? 1 : 0.8,
                      }}
                    >
                      {copiedLeagueId === id ? (
                        <CheckIcon
                          fontSize="small"
                          sx={{
                            transition: 'opacity 0.4s ease',
                          }}
                        />
                      ) : (
                        <ContentCopyIcon
                          fontSize="small"
                          sx={{
                            color: 'gray',
                            transition: 'opacity 0.4s ease',
                          }}
                        />
                      )}
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '2px' }}>
            <Button
              onClick={() => setInstructionsExpanded(!instructionsExpanded)}
              startIcon={
                <ExpandMoreIcon
                  sx={{ transform: instructionsExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              }
            >
              <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.75rem', sm: '.8rem', md: '.9rem' }, fontWeight: 500 }}>
                {instructionsExpanded ? 'Hide' : 'Where do I find my League ID?'}
              </Typography>
            </Button>
            <Collapse in={instructionsExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: '10px', width: '100%' }}>{displayInstructions()}</Box>
            </Collapse>
          </Box>
          {badRequestError && (
            <Alert severity="error" sx={{ my: 2 }}>
              Unable to pull rosters for league [{badRequestLeagueId}]. Check our instructions in "Where do I find my League ID?" and try again.
            </Alert>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};

export default FantraxLeagueInput;