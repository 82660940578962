import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../config/colors';
import PositionWithRank from '../PositionBox/PositionWithRank';

const PlayerTradeItem = ({ player, onDelete }) => {

  const responsiveFontSize = {
    fontSize: {
      xs: '0.75rem', // For extra-small devices
      sm: '0.85rem', // For small devices
      md: '1rem', // For medium devices, and so on
    },
    fontFamily: 'Avenir, Arial, sans-serif'
  };

  const getPrimaryPosition = (positions) => {
    if(!positions || positions.length === 0) {
      return;
    }

    let primaryPosition = positions[0];
    for(let i = 0; i < positions.length; i++) {
      if(positions[i] === 'SP') {
        // Default to SP if part of positions list
        primaryPosition = positions[i];
      }
    }
    return primaryPosition;
  }

  const renderValueWithExcess = (player) => {
    const valueWithExcess = player.value + player.excessValue;
    return (
      <Typography component="span" sx={{ ...responsiveFontSize, color: colors.secondary550, fontWeight: 'bold' }}>
        {valueWithExcess.toLocaleString()}
      </Typography>
    );
  };

  const primaryPosition = getPrimaryPosition(player.positions);

  return (

    <Paper elevation={1} sx={{ mb: 1, width: '100%', border: '1px solid', borderColor: colors.secondary500, backgroundColor: colors.secondary100 }}>
      <Box sx={{ px: { xs: 1, sm: 1.5 }, py: { xs: .5, sm: .75, md: 1 } }}>
        <Stack direction="row" spacing={.5} alignItems="center" sx={{ width: '100%' }}>
        <Typography
            variant="h6"
            component={Link}  // Set the component prop to Link
            to={`/player/${player.id}`}  // Pass the link to prop
            sx={{ flexGrow: 1, ...responsiveFontSize, textDecoration: 'underline', color: 'inherit', fontWeight: 600, pl: .5 }}  // Merge styles
          >
            {player.name}
          </Typography>
          {player.assetType === "PLAYER" ? 
            <>
              <Typography variant="body2" color="text.secondary" sx={responsiveFontSize}>
                {player.age}y
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{responsiveFontSize}}>
                ‧
              </Typography>
              <Box sx={{ pr: .5 }}>
                <PositionWithRank position={primaryPosition} customStyles={responsiveFontSize} rank={player.positionRanks[primaryPosition]} />
              </Box>
            </> :
            <>
              <Typography variant="body2" color="text.secondary" sx={{ ...responsiveFontSize, pr: .5 }}>
                {player.assetType}
              </Typography>
            </>
          }
          <Typography variant="body2" color="text.secondary" sx={{ borderLeft: 1, pl: 1, borderColor: 'grey.300', ...responsiveFontSize, fontWeight: 600 }}>
            {player.value === 10000 ? renderValueWithExcess(player) : player.value.toLocaleString()}
          </Typography>
          <Typography component="div">
            <IconButton onClick={() => onDelete(player)} aria-label="delete">
              <CloseIcon />
            </IconButton>
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default PlayerTradeItem;
