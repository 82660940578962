import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const styles = {
  bodyText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' },
    fontWeight: 500,
  },
};

const TradeDifferenceIndicator = ({ tradeDifference, success }) => {
  const [showWestIcon, setShowWestIcon] = useState(false);
  const [showEastIcon, setShowEastIcon] = useState(false);

  useEffect(() => {
    // Control visibility based on tradeDifference
    if (tradeDifference < 0 && !success) {
      setShowWestIcon(true);
      setShowEastIcon(false);
    } else if (tradeDifference > 0 && !success) {
      setShowEastIcon(true);
      setShowWestIcon(false);
    } else {
      setShowWestIcon(false);
      setShowEastIcon(false);
    }
  }, [tradeDifference, success]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        gap: { xs: '2px', sm: '4px', md: '8px' },
        flexWrap: 'nowrap',
      }}
    >
      {showWestIcon && (
        <ArrowLeftIcon
          sx={{
            fontSize: {xs: '1.5rem', sm: '1.6rem', md: '1.8rem'},
            color: 'text.secondary',
          }}
        />
      )}
      <Typography
        color={success ? "success.main" : "text.secondary"}
        sx={{
          ...styles.bodyText,
          textAlign: 'center',
          ml: { xs: showEastIcon ? '24px': 0,
             sm: showEastIcon ? '26px': 0,
             md: showEastIcon ? '28px': 0 },
          mr: { xs: showWestIcon ? '24px': 0,
             sm: showWestIcon ? '26px': 0,
             md: showWestIcon ? '28px': 0 },
          display: 'flex',
          alignItems: 'center',
          fontStyle: 'italic',
          pb: { xs: '3px', sm: '3px', md: '3px' }
        }}
      >
        {success ? (
          <>
            {/* HKB
            <VerifiedIcon 
              sx={{ 
                ml: 0.5, 
                fontSize: {xs: '1rem', sm: '1.1rem', md: '1.2rem'},
                color: 'success.main' 
              }} 
            /> */}
          </>
        ) : (
          `${tradeDifference > 0 ? 'Favors Team 2' : 'Favors Team 1'}`
        )}
      </Typography>
      {showEastIcon && (
        <ArrowRightIcon
          sx={{
            fontSize: {xs: '1.5rem', sm: '1.6rem', md: '1.8rem'},
            color: 'text.secondary',
          }}
        />
      )}
    </Box>
  );
};

export default TradeDifferenceIndicator;