import React from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
  ListItem
} from '@mui/material';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import { Link, useLocation } from 'react-router-dom';
import colors from '../../config/colors';
import logoImage from '../../assets/Slide1-removebg.png';

const drawerWidth = 240;

const Sidebar = () => {
  const location = useLocation();

  const isRouteActive = (route) => {
    return location.pathname.startsWith(route);
  };

  const renderSection = (title, items) => (
    <Box sx={{ px: 2, py: 1 }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          color: colors.primary400,
          fontFamily: 'Futura, sans-serif',
          textTransform: 'uppercase',
          my: 1,
        }}
      >
        {title}
      </Typography>
      <List disablePadding>
        {items.map(({ path, text, icon }) => {
          const active = isRouteActive(path);
          return (
            <ListItemButton
              key={text}
              component={Link}
              to={path}
              sx={{
                px: 2,
                py: 1,
                mb: 0.5,
                borderRadius: 1,
                backgroundColor: active ? colors.secondary300 : 'transparent',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: active ? colors.secondary400 : colors.secondary200,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: colors.grey500,
                  minWidth: 36,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.grey700,
                      fontFamily: 'Futura, sans-serif',
                      fontSize: '0.95rem',
                    }}
                  >
                    {text}
                  </Typography>
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );

  const sections = [
    {
      title: 'Dynasty Tools',
      items: [
        { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
        { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> },
        { path: '/league', text: 'League Breakdown', icon: <GroupIcon /> },
        { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
        { path: '/activity', text: 'Activity Feed', icon: <DynamicFeedIcon /> },
      ],
    },
    {
      title: 'General',
      items: [
        { path: '/faq', text: 'FAQ', icon: <InfoIcon /> },
        { path: '/contact', text: 'Contact Us', icon: <EmailIcon /> },
      ],
    },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#fafafa',
          borderRight: '1px solid #e0e0e0',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <ListItem button component={Link} to="/" key="LandingPage-sidebar"   
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <img src={logoImage} alt="Logo" style={{ maxHeight: 90 }} />
        </ListItem>
      </Box>
      <Divider />
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {renderSection(section.title, section.items)}
          <Divider sx={{ mt: 1 }} />
        </React.Fragment>
      ))}
    </Drawer>
  );
};

export default Sidebar;
