import React from 'react';
import { Typography, Card, CardContent, Box } from '@mui/material';
import colors from '../../config/colors';
import { isPositionPlayer, isPitcher, sortAndEnrichAllLevelPitcherStats, sortAndEnrichAllLevelHitterStats } from '../../utils/statsUtils';

const styles = {
    subtext: {
      fontSize: { xs: '.75rem', sm: '.85rem', md: '.95rem' },
      fontFamily: 'Avenir, Arial, sans-serif',
      paddingBottom: '6px',
      fontStyle: 'italic'
    },
    infotext: {
        fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
        fontFamily: 'Avenir, Arial, sans-serif',
      },
    boldinfotext: {
        fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' },
        fontFamily: 'Avenir, Arial, sans-serif',
        fontWeight: 600
    },
    heading: {
      fontSize: { xs: '1.3rem', sm: '1.4rem', md: '1.5rem' },
      fontWeight: 'bold', 
      fontFamily: 'Avenir, Arial, sans-serif',
    }
  };

const headerCellStyle = (widthFactor) => ({
    display: 'table-cell',
    p: 1,
    textAlign: 'center',
    minWidth: `${widthFactor * 10}px`,
});

const cellStyle = (widthFactor, level) => ({
    display: 'table-cell',
    p: 1,
    textAlign: 'center',
    minWidth: `${widthFactor * 10}px`,
    borderBottom: level === 'All' ? 1 : 0,
    borderColor: 'grey.300'
});

const StatsCard = ({ player }) => {

    const { playerInfo } = player;
    const { hitterStats, pitcherStats, positions } = playerInfo;
    const { allLevelStats: allLevelHitterStats } = hitterStats || {};
    const { allLevelStats: allLevelPitcherStats } = pitcherStats || {};
    const statsYear = hitterStats ? hitterStats.statsYear : pitcherStats ? pitcherStats.statsYear : null;

    const enrichedAllLevelHitterStats = sortAndEnrichAllLevelHitterStats(allLevelHitterStats)

    const enrichedAllLevelPitcherStats = sortAndEnrichAllLevelPitcherStats(allLevelPitcherStats)


    const levelMapping = {
        ROOKIE_BALL: "ROK",
        LOW_A: "A",
        HIGH_A: "A+",
        AA: "AA",
        AAA: "AAA",
        MLB: "MLB",
    };

  return (
    <Card
      sx={{ mb: 1, border: `1px solid ${colors.secondary500}`, width: '100%', height: '100%' }}
    >
      <CardContent 
        sx={{ 
            py: 1, 
            '&:last-child': {
            paddingBottom: 1.5,
          }
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 1, borderColor: 'grey.300', mb: 1 }}>
            <Typography
                variant="h6"
                sx={{ ...styles.heading, py: { xs: 1, md: 1.97 } }}
            >
                {statsYear} Stats
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            </Box>
        </Box>
        {isPitcher(positions) && pitcherStats && (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
                    {/* Header for Fixed Column */}
                    <Box sx={{ ...headerCellStyle(1), py: { xs: 2.3, sm: 2.4, md: 2.5 } }} />
                    {/* Data Rows for Fixed Column */}
                    {enrichedAllLevelPitcherStats.map((stats) => (
                        <Box sx={cellStyle(1)} key={`${stats.level}-fixed`}>
                            <Typography sx={styles.boldinfotext}>{levelMapping[stats.level] ?? stats.level}</Typography>
                        </Box>
                    ))}
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ overflowX: 'auto', width: '100%' }}>
                    <Box sx={{ display: 'table' }}>
                    {/* Header Row */}
                    <Box sx={{ display: 'table-row' }}>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>W</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>L</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>ERA</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1.5)}>
                            <Typography sx={styles.boldinfotext}>GP</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>GS</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>SV</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>IP</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>SO</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>WHIP</Typography>
                        </Box>
                    </Box>

                    {/* Data Rows */}
                    {enrichedAllLevelPitcherStats.map((stats) => (
                        <Box sx={{ display: 'table-row' }} key={stats.level}>
                            <Box sx={cellStyle(1, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.wins}</Typography>
                            </Box>
                            <Box sx={cellStyle(1, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.losses}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.era && stats.era.toFixed(2)}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.gamesPlayed}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.gamesStarted}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.saves}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                            <Typography sx={styles.infotext}>
                            {stats.inningsPitched &&
                                (stats.inningsPitched % 1 === 0
                                ? stats.inningsPitched
                                : stats.inningsPitched.toFixed(1))}
                            </Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.strikeOuts}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.whip && stats.whip.toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    ))}
                    </Box>
                </Box>
            </Box>
        )}
        {isPositionPlayer(positions) && hitterStats && (
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ position: 'sticky', left: 0, zIndex: 2, backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
                    {/* Header for Fixed Column */}
                    <Box sx={{ ...headerCellStyle(1), py: { xs: 2.3, sm: 2.4, md: 2.5 } }} />
                    {/* Data Rows for Fixed Column */}
                    {enrichedAllLevelHitterStats.map((stats) => (
                        <Box sx={cellStyle(1)} key={`${stats.level}-fixed`}>
                            <Typography sx={styles.boldinfotext}>{levelMapping[stats.level] ?? stats.level}</Typography>
                        </Box>
                    ))}
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ overflowX: 'auto', width: '100%' }}>
                    <Box sx={{ display: 'table' }}>
                    {/* Header Row */}
                    <Box sx={{ display: 'table-row' }}>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>G</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>PA</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>AB</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1.5)}>
                            <Typography sx={styles.boldinfotext}>R</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>RBI</Typography>
                        </Box>
                        <Box sx={headerCellStyle(1)}>
                            <Typography sx={styles.boldinfotext}>HR</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>SB</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>CS</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>BB</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>SO</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>BA</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>OBP</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>SLG</Typography>
                        </Box>
                        <Box sx={headerCellStyle(2)}>
                            <Typography sx={styles.boldinfotext}>OPS</Typography>
                        </Box>
                    </Box>

                    {/* Data Rows */}
                    {enrichedAllLevelHitterStats.map((stats) => (
                        <Box sx={{ display: 'table-row' }} key={stats.level}>
                            <Box sx={cellStyle(1, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.gamesPlayed}</Typography>
                            </Box>
                            <Box sx={cellStyle(1, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.plateAppearances}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.atBats}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.runs}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.rbi}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.homeRuns}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.stolenBases}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.caughtStealing}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.baseOnBalls}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.strikeOuts}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.avg && (stats.avg < 1 ? stats.avg.toFixed(3).substring(1) : stats.avg.toFixed(3))}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.obp && (stats.obp < 1 ? stats.obp.toFixed(3).substring(1) : stats.obp.toFixed(3))}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.slg && (stats.slg < 1 ? stats.slg.toFixed(3).substring(1) : stats.slg.toFixed(3))}</Typography>
                            </Box>
                            <Box sx={cellStyle(2, stats.level)}>
                                <Typography sx={styles.infotext}>{stats.ops && (stats.ops < 1 ? stats.ops.toFixed(3).substring(1) : stats.ops.toFixed(3))}</Typography>
                            </Box>
                        </Box>
                    ))}
                    </Box>
                </Box>
            </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default StatsCard;
