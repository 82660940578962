import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Paper, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import colors from '../../config/colors';
import PositionBox from '../PositionBox/PositionBox';

const PlayerRow = ({ 
  player,
  index,
  styles,
  isSmallScreen,
  playerSpace,
  teamSpace,
  ageSpace,
  positionSpace,
  valueSpace,
  isFiltered
}) => {
  const renderLevel = (level) => {
    let levelText = level;
    if(level === 'HIGH_A') {
      levelText = 'A+';
    } else if(level === 'LOW_A') {
      levelText = 'A';
    } else if(level === 'ROOKIE_BALL') {
      levelText = 'ROK';
    }
    return levelText;
  }

  const renderValueChangeIcon = (rank, valueChange, mediumThreshold) => {
    const changeMultipler = Math.min(10, Math.max(1, rank / 25));
    const calculatedMediumThreshold = changeMultipler * mediumThreshold;
    const largeThreshold = calculatedMediumThreshold * 2;
    const Icon = Math.abs(valueChange) < calculatedMediumThreshold ? null : (valueChange > 0 ? (valueChange > largeThreshold ? ArrowDownwardIcon : CallReceivedIcon) : (valueChange < (largeThreshold * -1) ? ArrowUpwardIcon : CallMadeIcon));

    const color =
    valueChange > 0
      ? 'error.main' // Downward icons
      : 'success.main';

    return (
      Icon && <Icon sx={{ fontSize: { xs: '.9rem', sm: '1rem', md: '1.2rem'}, color: color }}/>
    );
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ display: 'flex', alignItems: 'stretch' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={styles.icon}>{player.originalIndex + 1}</Box>
        <Paper
          sx={{
            ...styles.row,
            height: '100%',
            width: '100%',
          }}
        >
          <Grid container>
            <Grid item xs={playerSpace}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                  ...styles.columnElement,
                  px: 1,
                }}
              >
                <Typography
                  component={Link}
                  to={`/player/${player.id}`}
                  sx={{
                    ...styles.text,
                    marginRight: 0,
                    '&:hover': {
                      color: colors.primary400
                    }
                  }}
                >
                  {player.name}
                </Typography>
                {player.assetType === "PLAYER" && (
                  <>
                    <Typography 
                      component="span" 
                      color="text.secondary" 
                      sx={{ 
                        ...styles.italicText
                      }}
                    >
                      &nbsp;‧&nbsp;
                    </Typography>
                    <Typography 
                      component="span" 
                      color="text.secondary" 
                      sx={{ 
                        ...styles.italicText
                      }}
                    >
                      {renderLevel(player.level)}&nbsp;
                    </Typography>
                    {!isSmallScreen && (
                      <Typography 
                        component="span" 
                        color="text.secondary" 
                        sx={{ 
                          ...styles.italicText,
                        }}
                      >
                        ‧&nbsp;
                      </Typography>
                    )}
                    {!isSmallScreen && (
                      <Typography 
                        component="span" 
                        color="text.secondary" 
                        sx={{ 
                          ...styles.italicText
                        }}
                      >
                        {player.team}
                      </Typography>
                    )}
                    {/* {isFiltered && (
                      <Typography 
                        component="span" 
                        color="text.secondary" 
                        sx={{ 
                          ...styles.italicText,
                        }}
                      >
                        ‧&nbsp;
                      </Typography>
                    )}
                    {isFiltered && (
                      <Typography 
                        component="span" 
                        color="text.secondary" 
                        sx={{ 
                          ...styles.italicText
                        }}
                      >
                        {index + 1}
                      </Typography>
                    )} */}
                  </>
                )}
              </Box>
            </Grid>
            {isSmallScreen && <Grid item sm={teamSpace}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {player.assetType === 'PLAYER' && (
                  <Typography
                    sx={{
                      ...styles.textSecondary,
                      ...styles.columnElement,
                    }}
                  >
                    {player.team}
                  </Typography>
                )}
            </Grid>}
            {isSmallScreen && <Grid item sm={ageSpace}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {player.assetType === 'PLAYER' && (
                  <Typography
                    sx={{
                      ...styles.textSecondary,
                      ...styles.columnElement,
                    }}
                  >
                    {player.age}
                  </Typography>
                )}
            </Grid>}
            <Grid item xs={positionSpace}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              {player.assetType === 'PLAYER' ? (
                <Typography
                  sx={{
                    ...styles.textSecondary,
                    ...styles.columnElement,
                  }}
                >
                  {player.positions && player.positions.map((position, posIdx) => (
                    <PositionBox 
                      key={`${player.id}-${position}`} 
                      position={position} 
                      rank={player.positionRanks[position]} 
                    />
                  ))}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    ...styles.textSecondary,
                    ...styles.columnElement,
                  }}
                >
                  <PositionBox key={`${player.id}-PICK`} position="PICK" />
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={valueSpace}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                {renderValueChangeIcon(player.originalIndex + 1, player.rankChange7Days * -1, 2)}
                <Typography
                  sx={{
                    ...styles.text,
                    ...styles.columnElement,
                    marginRight: 1,
                    color: player.value === 10000 ? colors.secondary600 : 'black'
                  }}
                >
                  {player.value && player.value.toLocaleString()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};

export default PlayerRow;