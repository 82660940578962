import React, { useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HorizontalPlayerCard from './HorizontalPlayerCard';
import colors from '../../config/colors';

const getTimeAgo = (timestamp) => {
  const now = new Date();
  const diff = Math.floor((now - timestamp) / 1000);

  if (diff < 60) return "Just now";
  if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  }
  if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  }
  if (diff < 172800) return "Yesterday";
  if (diff < 604800) {
    const days = Math.floor(diff / 86400);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  }
  if (diff < 2419200) {
    const weeks = Math.floor(diff / 604800);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  }
  if (diff < 31536000) {
    const months = Math.floor(diff / 2419200);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  }

  const years = Math.floor(diff / 31536000);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};

const styles = {
  subtext: {
    fontSize: { xs: '.75rem', sm: '.85rem', md: '.95rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    paddingBottom: '6px',
    fontStyle: 'italic'
  },
  heading: {
    fontSize: { xs: '1.3rem', sm: '1.4rem', md: '1.5rem' },
    fontWeight: 'bold', 
    fontFamily: 'Avenir, Arial, sans-serif',
  }
};

const HorizontalSelectionList = ({ selections, isSmallScreen, playerId, name, isActive }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen((prev) => !prev);
  };

  const renderInactiveIcon = () => {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}>
              Inactive players are determined by league ownership percentages and are not eligible for player selections. Active statuses are refreshed weekly.
            </Typography>
          }                  
          placement="top"
          open={open}
          onClose={handleTooltipClose}
          disableFocusListener
            disableHoverListener
            disableTouchListener
        >
          <IconButton size="small" onClick={handleTooltipToggle}>
            <InfoOutlinedIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const infoText = selections && selections.length !== 0 ? `The most recent player selections involving ${name}${!isActive ? `. ${name} is currently inactive.` : ''}` : `No recent player selections for ${name}${!isActive ? `. ${name} is currently inactive.` : ''}`;

  return (
    <Card
      sx={{ my: 1, border: `1px solid ${colors.secondary500}`, width: '100%' }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', borderBottom: 1, borderColor: 'grey.300', mb: { xs: 1, sm: 1.5, md: 2 }, pb: .5 }}>
          <Typography
            variant="h6"
            sx={styles.heading}
          >
            Recent Selections
          </Typography>
          <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={styles.subtext}
            >
            {infoText}{!isActive && renderInactiveIcon()}
          </Typography>
        </Box>
        <Grid container spacing={isSmallScreen ? 1.5 : 3}  >
          {selections.map((selection, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <Box
                sx={{
                  borderRadius: 2,
                  }}
              >
                <Box sx={{ px: 1, textAlign: 'left' }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'grey',
                            fontFamily: 'Avenir, Arial, sans-serif',
                            fontSize: { xs: '0.6rem', sm: '.75rem', md: '.9rem' },
                        }}
                    >
                        {getTimeAgo(new Date(selection.eventTimestamp))}
                    </Typography>
                </Box>

                <Box sx={{ marginTop: 1 }}>
                  {selection.players
                    .slice()
                    .sort((a, b) => a.selectionIndex - b.selectionIndex)
                    .map((player, playerIndex) => (
                      <Box
                        key={playerIndex}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          marginBottom: 1,
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: { xs: 14, sm: 18, md: 24 },
                            height: { xs: 14, sm: 18, md: 24 },
                            fontSize: { xs: '.6rem', sm: '.7rem', md: '.8rem' },
                            borderRadius: '20%',
                            backgroundColor: colors.primary400,
                            border: '1px solid',
                            borderColor: colors.primary500,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            fontFamily: 'Avenir, Arial, sans-serif',
                            fontWeight: 'bold',
                            zIndex: 100,
                          }}
                        >
                          {playerIndex + 1}
                        </Box>
                        <HorizontalPlayerCard player={player} highlighted={playerId === player.playerId} />
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HorizontalSelectionList;
