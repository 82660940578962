import React from 'react';
import { Box } from '@mui/material';

const PositionWithRank = ({ position, customStyles, rank }) => {

  return (
    <Box 
      component="span"
      sx={{ 
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: 'text.secondary',
        fontSize: {
          xs: '0.65rem',
          sm: '0.65rem',
          md: '0.85rem',
        },
        whiteSpace: 'nowrap',
        pb: { xs: .5, sm: .3, md: .2 },
        ...customStyles,
      }}
    >
      <span>{position}</span>
      {rank && rank < 100 && (
        <span
          style={{
            fontSize: '0.85em',
          }}
        >
          /{rank}
        </span>
      )}
    </Box>
  );
};

export default PositionWithRank;
