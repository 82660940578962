import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Box, Container, CircularProgress, Button, Typography } from '@mui/material';
import TradePackageBuilder from './TradePackageBuilder';
import Title from '../Title/Title';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { calculateTeamValue, calculateRequiredValue, getMaxPlayerValue } from '../../utils/tradeUtils';
import logo from '../../assets/TradeCalculator.png';
import TradeValueSlider from './TradeValueSlider';

import colors from '../../config/colors';

const styles = {
  buttonText: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' },
    fontWeight: 500,
  },
};

const TradeCalculator = ({ playerRankings, error }) => {
  const [teamOnePlayers, setTeamOnePlayers] = useState([]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([]);
  const [loadingPlayers, setLoadingPlayers] = useState(false);
  const [tradeCopied, setTradeCopied] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the URL and update team players based on playerRankings
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const teamOneIds = searchParams.get('teamOne')?.split(',') || [];
    const teamTwoIds = searchParams.get('teamTwo')?.split(',') || [];

    if(playerRankings && playerRankings.length > 0) {
      setLoadingPlayers(false);
      // Filter playerRankings based on the IDs from the URL
      const filteredTeamOnePlayers = teamOneIds.map(id => 
        playerRankings.find(player => player.id.toString() === id)
      ).filter(Boolean);

      const filteredTeamTwoPlayers = teamTwoIds.map(id => 
        playerRankings.find(player => player.id.toString() === id)
      ).filter(Boolean);
      
      setTeamOnePlayers(filteredTeamOnePlayers);
      setTeamTwoPlayers(filteredTeamTwoPlayers);
    } else {
      if(teamOneIds.length > 0 || teamTwoIds.length > 0) {
        setLoadingPlayers(true);
      }
    }
  }, [location.search, playerRankings]);

    const handleAddPlayerToTeamOne = (player) => {
      if (player && (player.assetType === "PICK" || !teamOnePlayers.some(p => p.name === player.name))) {
        setTeamOnePlayers(prev => [...prev, player]);
        
        const searchParams = new URLSearchParams(location.search);
        const teamOneIds = searchParams.get('teamOne')?.split(',').filter(Boolean) || [];
        teamOneIds.push(player.id);
        searchParams.set('teamOne', teamOneIds.join(','));
        navigate(`?${searchParams.toString()}`, { replace: true });
      }
    };


    const handleAddPlayerToTeamTwo = (player) => {
      if (player && (player.assetType === "PICK" || !teamTwoPlayers.some(p => p.name === player.name))) {
        setTeamTwoPlayers(prev => [...prev, player]);
    
        const searchParams = new URLSearchParams(location.search);
        const teamTwoIds = searchParams.get('teamTwo')?.split(',').filter(Boolean) || [];
        teamTwoIds.push(player.id);
        searchParams.set('teamTwo', teamTwoIds.join(','));
        navigate(`?${searchParams.toString()}`, { replace: true });
      }
    };

  const handleDeletePlayerFromTeamOne = (indexToDelete) => {
    setTeamOnePlayers((prevPlayers) =>
      prevPlayers.filter((_, i) => i !== indexToDelete)
    );
  
    const searchParams = new URLSearchParams(location.search);
    const teamOneIds = searchParams.get('teamOne')?.split(',') || [];
    teamOneIds.splice(indexToDelete, 1);
    searchParams.set('teamOne', teamOneIds.join(','));
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleDeletePlayerFromTeamTwo = (indexToDelete) => {
    setTeamTwoPlayers((prevPlayers) =>
      prevPlayers.filter((_, i) => i !== indexToDelete)
    );

    const searchParams = new URLSearchParams(location.search);
    const teamTwoIds = searchParams.get('teamTwo')?.split(',') || [];
    teamTwoIds.splice(indexToDelete, 1);
    searchParams.set('teamTwo', teamTwoIds.join(','));
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setTradeCopied(true);
        setTimeout(() => setTradeCopied(false), 3000);
      })
      .catch(err => console.error("Failed to copy URL:", err));
  };

  const  { teamValue: teamOneValueWithoutDepreciation, depreciationValue: teamOneDepreciation } = calculateTeamValue(teamOnePlayers, teamTwoPlayers);
  const  { teamValue: teamTwoValueWithoutDepreciation, depreciationValue: teamTwoDepreciation } = calculateTeamValue(teamTwoPlayers, teamOnePlayers);
  
  let adjustedTeamOneDepreciation = teamOneDepreciation;
  let adjustedTeamTwoDepreciation = teamTwoDepreciation;
  
  // Only keep the highest depreciation amount and subtract the other from that
  if (teamOneDepreciation > teamTwoDepreciation) {
    adjustedTeamOneDepreciation -= teamTwoDepreciation;
    adjustedTeamTwoDepreciation = 0; // Since we subtract the entire amount from the lower depreciation
  } else {
    adjustedTeamTwoDepreciation -= teamOneDepreciation;
    adjustedTeamOneDepreciation = 0; // Since we subtract the entire amount from the lower depreciation
  }

  const maxTeamOnePlayerValue = getMaxPlayerValue(teamOnePlayers);
  const maxTeamTwoPlayerValue = getMaxPlayerValue(teamTwoPlayers);

  let teamOneValue = teamOneValueWithoutDepreciation - adjustedTeamOneDepreciation;
  let teamTwoValue = teamTwoValueWithoutDepreciation - adjustedTeamTwoDepreciation;

  if (adjustedTeamOneDepreciation > 0 && maxTeamOnePlayerValue > maxTeamTwoPlayerValue && teamOneValue > teamTwoValue) {
    teamOneValue += adjustedTeamOneDepreciation;
    adjustedTeamOneDepreciation = 0;
  } else if(adjustedTeamTwoDepreciation > 0 && maxTeamTwoPlayerValue > maxTeamOnePlayerValue && teamTwoValue > teamOneValue) {
    teamTwoValue += adjustedTeamTwoDepreciation;
    adjustedTeamTwoDepreciation = 0;
  }

  const tradeDifference = teamTwoValue - teamOneValue;
  const calculatedThreshold = Math.min(400, (Math.max(teamOneValue, teamTwoValue) / 18));
  const acceptanceThreshold = teamOneValue !== 0 || teamTwoValue !== 0 ? Math.max(20, calculatedThreshold) : null;
  const success = teamOnePlayers.length && teamTwoPlayers.length && Math.abs(tradeDifference) < acceptanceThreshold;

  return (
    <>
      <HeadWrapper 
        title="Dynasty Baseball Trade Calculator" 
        description="Dynasty fantasy baseball trade calculator! 100% crowdsourced player and pick values give you the most accurate, up-to-date trade evaluations. Analyze your trades today!" 
        image={logo} 
      />
      <Title pageName="Trade Calculator" extraText="Dynasty, keep forever, standard points scoring. Select one or more players or picks for each side." showPickerLink={true} showRankingsLink={false}  />
      <Container width="md">
      {error && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>Error retrieving player set. Please check your internet connection or try again later.</Alert>
      )}
        {
          loadingPlayers && !error ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress sx={{ color: colors.primary400 }} />
            </Box>
          ) : (
            <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 6,
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: 4,
                },
                backgroundColor: 'white'
              }}> 
                <TradePackageBuilder 
                  playerRankings={playerRankings}
                  teamNumber={1}
                  teamPlayers={teamOnePlayers}
                  addTeamPlayer={handleAddPlayerToTeamOne}
                  deleteTeamPlayer={handleDeletePlayerFromTeamOne}
                  teamValue={teamOneValue}
                  depreciationValue={adjustedTeamOneDepreciation}
                  success={success}
                  closeTheGapValue={calculateRequiredValue(teamOnePlayers, teamTwoPlayers, (teamOneValueWithoutDepreciation - teamOneDepreciation), (teamTwoValueWithoutDepreciation - teamTwoDepreciation))}
                  allUsedPlayers={teamOnePlayers.concat(teamTwoPlayers)}
                  showSuggestions={tradeDifference > 0 ? true : false}
                />
                <TradePackageBuilder 
                  playerRankings={playerRankings}
                  teamNumber={2}
                  teamPlayers={teamTwoPlayers}
                  addTeamPlayer={handleAddPlayerToTeamTwo}
                  deleteTeamPlayer={handleDeletePlayerFromTeamTwo}
                  teamValue={teamTwoValue}
                  depreciationValue={adjustedTeamTwoDepreciation}
                  success={success}
                  closeTheGapValue={calculateRequiredValue(teamOnePlayers, teamTwoPlayers, (teamOneValueWithoutDepreciation - teamOneDepreciation), (teamTwoValueWithoutDepreciation - teamTwoDepreciation))}
                  allUsedPlayers={teamOnePlayers.concat(teamTwoPlayers)}
                  showSuggestions={tradeDifference > 0 ? false : true}
                />
              </Box>
              <Box sx={{ 
                mt: 4, 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                alignContent: 'center', 
                justifyContent: 'center',
                '@media (min-width: 900px)': {
                  width: '80%',
                } 
              }}>
                <TradeValueSlider 
                  teamOneValue={teamOneValue}
                  teamTwoValue={teamTwoValue}
                  tradeDifference={tradeDifference}
                  acceptanceThreshold={acceptanceThreshold}
                  success={success}
                />
              {acceptanceThreshold !== null && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: { xs: .75, sm: 1, md: 1.25 } }}>
                  <Button 
                    variant="text"
                    onClick={handleCopyUrl}
                    sx={{ borderRadius: 1, p: 1, display: 'flex', alignItems: 'center', gap: 1, color: success ? 'success.main' : 'error.main' }}
                  >
                    {tradeCopied ? (
                      <CheckIcon
                        fontSize="small"
                        sx={{
                          transition: 'opacity 0.4s ease',
                        }}
                      />
                    ) : (
                      <ContentCopyIcon
                        fontSize="small"
                        sx={{
                          transition: 'opacity 0.4s ease',
                        }}
                      />
                    )}
                    <Typography sx={{ ...styles.buttonText, fontWeight: 600 }}>
                      Copy Trade URL
                    </Typography>
                  </Button>
                </Box>
              )}
              </Box>
            </Box>
          )
        }

      </Container>
    </>
  );
};

export default TradeCalculator;