import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Collapse,
  IconButton,
  Paper,
  Link,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import { checkYahooAuthStatus, validateYahooLeague, getYahooAuthUrl } from '../../../api/apiService'; 
import { useYahooLeagueBreakdown } from '../../../hooks/league/YahooLeagueContext';
import { LEAGUE_SOURCES } from '../../../hooks/league/FantasyServiceContext';
import colors from '../../../config/colors';
import YahooConnectIcon from '../../../assets/Yahoo1.png';

const YahooLeagueInput = ({ setLeague }) => {
  const navigate = useNavigate();
  const [instructionsExpanded, setInstructionsExpanded] = useState(false);
  const [leagueId, setLeagueId] = useState('');
  const [lastUsedLeagueIds, setLastUsedLeagueIds] = useState([]);
  const [copiedLeagueId, setCopiedLeagueId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    loading,
    badRequestError,
    badRequestLeagueId,
    badRequestErrorMessage,
  } = useYahooLeagueBreakdown();

  useEffect(() => {
    // Check authentication status when component mounts
    const checkAuthStatus = async () => {
      try {
        setAuthLoading(true);
        const response = await checkYahooAuthStatus();
        setIsAuthenticated(response.data.authenticated && !response.data.tokenExpired);
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsAuthenticated(false);
      } finally {
        setAuthLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const savedLeagueIds = JSON.parse(localStorage.getItem('yahooLastUsedLeagueIds')) || [];
    setLastUsedLeagueIds(savedLeagueIds);
  }, []);

  const handleSubmit = async () => {
    const response = await checkYahooAuthStatus();
    const isAuthed = response.data.authenticated && !response.data.tokenExpired;

    if (!isAuthed) {
      // If not authenticated, redirect to login
      window.location.href = getYahooAuthUrl();
      return;
    }
  
    try {
      // Validate league ID first
      const validationResponse = await validateYahooLeague(leagueId);
  
      if (validationResponse.data.authenticated) {

        const success = await setLeague(leagueId, LEAGUE_SOURCES.YAHOO);

        if (success) {
          const updatedLeagueIds = [
            leagueId,
            ...lastUsedLeagueIds.filter((id) => id !== leagueId),
          ].slice(0, 3);
          localStorage.setItem(
            'yahooLastUsedLeagueIds',
            JSON.stringify(updatedLeagueIds)
          );
          setLastUsedLeagueIds(updatedLeagueIds);
          navigate(`/league/${leagueId}/rankings`);
        }
      } else {
        throw new Error(validationResponse.data.error || 'Failed to validate league ID');
      }
    } catch (error) {
      console.error('Error validating league ID:', error);
    }
  };



  const handleCopyLeagueId = (id) => {
    navigator.clipboard.writeText(id);
    setCopiedLeagueId(id);

    // Reset the icon to the default state after 2 seconds
    setTimeout(() => setCopiedLeagueId(null), 3000);
  };

  const handleYahooLogin = () => {
    window.location.href = getYahooAuthUrl();
  };

  const displayInstructions = () => (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: 1,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        width: '90%',
        margin: '0 auto',
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        1. Visit <Link href="https://sports.yahoo.com/fantasy/" target="_blank" rel="noopener noreferrer">Yahoo Fantasy Sports</Link>.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        2. Click the name of one of your Yahoo Fantasy Sports leagues.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        3. Mouse over <strong>League</strong>, then click <strong>Settings</strong>.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginBottom: 1, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        4. The League ID number is listed first in your league's settings.
      </Typography>
      <Typography variant="body2" sx={{ fontFamily: 'Avenir, Arial, sans-serif', marginTop: 2, fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }  }}>
        For more details, visit the 
        <Link href="https://help.yahoo.com/kb/find-group-leagues-number-sln8238.html" target="_blank" rel="noopener noreferrer"> Yahoo Help Page</Link>.
      </Typography>
    </Box>
  );
  

  if (authLoading) {
    return (
      <Paper sx={{ padding: 2, maxWidth: 600, margin: '0 auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Paper sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 1, boxShadow: 2 }}>
        <Stack spacing={2}>
          <Typography
            sx={{
                fontFamily: 'Futura, Arial, sans-serif',
                fontWeight: 600,
                paddingLeft: '6px',
                fontSize: { xs: 20, sm: 22, md: 24 },
                display: 'flex',
                marginBottom: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
                color: colors.primary400
            }}
          >
            Yahoo
          </Typography>
          {!isAuthenticated ? (
            <Alert
              severity="warning"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleYahooLogin}
                  sx={{ minWidth: 'auto', padding: '4px 3px' }}
                >
                  <Box
                    component="img"
                    src={YahooConnectIcon}
                    alt="Connect to Yahoo"
                    sx={{
                      width: 'auto',
                      height: {
                        xs: '32px',
                        sm: '34px',
                        md: '36px'
                      }
                    }}
                  />
                </Button>
              }
            >
              <Typography
                sx={{
                  fontFamily: "Avenir, Arial, sans-serif",
                  fontSize: {
                    xs: "0.75rem",
                    sm: "0.8rem",
                    md: ".9rem",
                  },
                  fontWeight: 500,
                }}
              >
                {isSmallScreen ? 'Sign in to access league data.' : 'Sign in to Yahoo Fantasy to access your league data.'}
              </Typography>
            </Alert>
          ) : (
            <>
              <TextField
                id="league-id-input"
                name="leagueId"
                label="Yahoo League ID"
                variant="outlined"
                value={leagueId}
                onChange={(e) => setLeagueId(e.target.value)}
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading || !leagueId || leagueId.length === 0}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
              </Button>
            </>
          )}

          {lastUsedLeagueIds.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1,
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                <IconButton
                  size="small"
                  disabled
                  sx={{ marginLeft: '4px',
                    padding: '4px',
                    color: 'grey',
                    '& .MuiSvgIcon-root': {
                      color: 'grey',
                    },
                  }}
                >
                  <HistoryIcon fontSize="small" />
                </IconButton>
                {lastUsedLeagueIds.map((id, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '4px 8px',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleCopyLeagueId(id)}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Avenir, Arial, sans-serif',
                        whiteSpace: 'nowrap',
                        fontSize: { xs: '.8rem', sm: '.9rem', md: '1rem' }
                      }}
                    >
                      {id}
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        padding: '4px',
                        transition: 'transform 0.4s ease, opacity 0.4s ease',
                        transform: copiedLeagueId === id ? 'scale(1.2)' : 'scale(1)',
                        opacity: copiedLeagueId === id ? 1 : 0.8,
                      }}
                    >
                      {copiedLeagueId === id ? (
                        <CheckIcon
                          fontSize="small"
                          sx={{
                            transition: 'opacity 0.4s ease',
                          }}
                        />
                      ) : (
                        <ContentCopyIcon
                          fontSize="small"
                          sx={{
                            color: 'gray',
                            transition: 'opacity 0.4s ease',
                          }}
                        />
                      )}
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '2px' }}>
            <Button
              onClick={() => setInstructionsExpanded(!instructionsExpanded)}
              startIcon={
                <ExpandMoreIcon
                  sx={{ transform: instructionsExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              }
            >
              <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.75rem', sm: '.8rem', md: '.9rem' }, fontWeight: 500 }}>
                {instructionsExpanded ? 'Hide' : 'Where do I find my League ID?'}
              </Typography>
            </Button>
            <Collapse sx={{ width: '100%' }} in={instructionsExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ marginTop: '10px', width: '100%' }}>{displayInstructions()}</Box>
            </Collapse>
          </Box>
          {badRequestError && (
            <Alert severity="error" sx={{ my: 2 }}>
              {badRequestErrorMessage ? badRequestErrorMessage : `Unable to pull rosters for league [${badRequestLeagueId}]. Check our instructions in "Where do I find my League ID?" and try again.`}
            </Alert>
          )}
        </Stack>
      </Paper>
    </Box>

  );
};

export default YahooLeagueInput;