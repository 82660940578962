import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlayerList from '../PlayerList/PlayerList';
import Filters from '../Filter/Filters';
import Title from '../Title/Title';
import { Alert, TextField, Box, IconButton, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import HeadWrapper from '../HeadWrapper/HeadWrapper';
import colors from '../../config/colors';

import logo from '../../assets/Rankings.png';

const Rankings = ({ players, topRisers, topFallers, error, lastUpdated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse initial URL parameters
  const initialParams = new URLSearchParams(location.search);
  const initialPositions = initialParams.get('positions') ? initialParams.get('positions').split(',') : [];
  const initialLevel = initialParams.get('level') || '';
  const initialSearch = initialParams.get('search') || '';

  // Initialize state with URL parameters
  const [filterText, setFilterText] = useState(initialSearch);
  const [selectedPositions, setSelectedPositions] = useState(initialPositions);
  const [selectedLevel, setSelectedLevel] = useState(initialLevel);
  const [majorsFilter, setMajorsFilter] = useState(initialLevel === "Major Leaguers Only");
  const [minorsFilter, setMinorsFilter] = useState(initialLevel === "Minor Leaguers Only");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [displayedPlayers, setDisplayedPlayers] = useState((players && players.length > 0) ? players : null);

  // Parse filters from URL on mount only
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const positions = params.get('positions') ? params.get('positions').split(',') : [];
    const level = params.get('level') || '';
    const search = params.get('search') || '';
  
    setSelectedPositions(positions);
    setSelectedLevel(level);
    setFilterText(search);
  
    if (level === "Major Leaguers Only") {
      setMajorsFilter(true);
      setMinorsFilter(false);
    } else if (level === "Minor Leaguers Only") {
      setMinorsFilter(true);
      setMajorsFilter(false);
    } else {
      setMajorsFilter(false);
      setMinorsFilter(false);
    }
  }, [location.search]);

  // Update displayed players when filters change
  useEffect(() => {
    const removeDiacritics = (str) => 
      str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    const debounceFilter = debounce((searchText) => {
      if (!players || players.length === 0) return;
      
      const normalizedSearchText = removeDiacritics(searchText.toLowerCase());
    
      const filtered = players.filter(player => {
        let playerPositionsArray = player.positions || [];
        let levelCheck = true;
        if ((majorsFilter && (!player.majorLeaguer || player.assetType === "PICK")) ||
            (minorsFilter && (player.majorLeaguer || player.assetType === "PICK"))) {
          levelCheck = false;
        }
    
        // Normalize player name and search text
        const normalizedPlayerName = removeDiacritics(player.name.toLowerCase());
    
        return normalizedPlayerName.includes(normalizedSearchText) &&
          (selectedPositions.length === 0 || playerPositionsArray.some(position => selectedPositions.includes(position))) &&
          levelCheck;
      });
    
      setDisplayedPlayers(filtered);
      setFiltersApplied(selectedPositions.length > 0 || majorsFilter || minorsFilter);
      setSearchApplied(filterText && filterText.length > 0);
    }, 250);

    debounceFilter(filterText);
    return () => debounceFilter.cancel();
  }, [filterText, selectedPositions, selectedLevel, players, majorsFilter, minorsFilter]);

  // Update URL when filter or search changes
  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedPositions.length > 0) {
      params.set('positions', selectedPositions.join(','));
    }
    if (selectedLevel) {
      params.set('level', selectedLevel);
    }
    if (filterText) {
      params.set('search', filterText);
    }
  
    navigate({ search: params.toString() }, { replace: true });
  }, [selectedPositions, selectedLevel, filterText, navigate]);

  const handlePositionChange = (event, newPosition) => {
    if (selectedPositions.includes(newPosition)) {
      setSelectedPositions(selectedPositions.filter(position => position !== newPosition));
    } else {
      setSelectedPositions([...selectedPositions, newPosition]);
    }
  };

  const handleLevelChange = (newLevel) => {
    setSelectedLevel(newLevel);
    if (newLevel === "Major Leaguers Only") {
      setMajorsFilter(true);
      setMinorsFilter(false);
    } else if (newLevel === "Minor Leaguers Only") {
      setMinorsFilter(true);
      setMajorsFilter(false);
    } else {
      setMajorsFilter(false);
      setMinorsFilter(false);
    }
  };

  const clearFilters = () => {
    setSelectedPositions([]);
    setSelectedLevel('');
    setMajorsFilter(false);
    setMinorsFilter(false);
  };

  const handleClearSearch = () => {
    setFilterText('');
  };

  const levels = ["Major Leaguers Only", "Minor Leaguers Only"];

  const getHeadWrapperTitle = () => {
    const positionTitles = new Map([
      ['C', 'Dynasty Catcher Rankings'],
      ['1B', 'Dynasty First Base Rankings'],
      ['2B', 'Dynasty Second Base Rankings'],
      ['3B', 'Dynasty Third Base Rankings'],
      ['SS', 'Dynasty Shortstop Rankings'],
      ['OF', 'Dynasty Outfield Rankings'],
      ['SP', 'Dynasty Starting Pitcher Rankings'],
      ['RP', 'Dynasty Relief Pitcher Rankings'],
    ]);
  
    if (selectedPositions.length === 1) {
      return positionTitles.get(selectedPositions[0]) || "Dynasty Baseball Rankings";
    }
  
    const hasPositions = (pos1, pos2) => 
      selectedPositions.includes(pos1) && selectedPositions.includes(pos2);
  
    if (selectedPositions.length === 2) {
      if (hasPositions('1B', '3B')) return "Dynasty Corner Infield Rankings";
      if (hasPositions('SS', '2B')) return "Dynasty Middle Infield Rankings";
    }
  
    if (selectedLevel === "Major Leaguers Only") return "Dynasty MLB Rankings";
    if (selectedLevel === "Minor Leaguers Only") return "Dynasty Minor League Prospect Rankings";
  
    return "Dynasty Baseball Rankings";
  };

  return (
    <Box sx={{ p: 0.6 }}>
      <HeadWrapper 
        title={getHeadWrapperTitle()}
        description="Crowdsourced dynasty baseball rankings. We use 100% crowdsourced player and pick values to give you the most accurate, up-to-date dynasty baseball rankings." 
        image={logo}
      />
      <Title 
        pageName="Dynasty Baseball Rankings" 
        extraText={`Crowdsourced dynasty baseball rankings. Last updated ${lastUpdated}.`} 
        showPickerLink={true} 
        showRankingsLink={false}
      />
  
      <Box sx={{ mb: '10px', backgroundColor: 'white' }}>
  
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2, width: '100%' }}>
            Error retrieving rankings. Please check your internet connection or try again later.
          </Alert>
        )}
  
        <Box sx={{ position: 'relative', width: '100%', mb: '6px' }}>
          <TextField
            label="Search Players"
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            sx={{
              width: '100%',
              '& .MuiInputLabel-root': {
                fontSize: { xs: '0.85rem', sm: '0.9rem', md: '1rem' },
                fontFamily: 'Avenir, Arial, sans-serif',
                fontWeight: 500
              },
              '& .MuiInputBase-input': {
                fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                fontFamily: 'Avenir, Arial, sans-serif',
                fontWeight: 500
              },
            }}
          />
          {filterText && 
            <IconButton
              size="small"
              onClick={handleClearSearch}
              sx={{
                position: 'absolute',
                right: 10,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        </Box>
  
        <Filters
          selectedPositions={selectedPositions}
          onPositionChange={handlePositionChange}
          levels={levels}
          selectedLevel={selectedLevel}
          onLevelChange={handleLevelChange}
          clearFilters={clearFilters}
        />
      </Box>
  
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
        <Box sx={{ flex: 3 }}>
          {displayedPlayers ? (
            <PlayerList players={displayedPlayers} isFiltered={filtersApplied} isSearched={searchApplied} />
          ) : (
            !error && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress sx={{ color: colors.primary400 }} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
  
};

export default Rankings;