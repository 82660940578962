export const isPositionPlayer = (positions) => {
    if(!positions) {
        return false;
    }

    if(positions.includes('C') ||
        positions.includes('1B') ||
        positions.includes('2B') ||
        positions.includes('3B') ||
        positions.includes('SS') ||
        positions.includes('OF') ||
        positions.includes('UT')) {
            return true;
        } else {
            return false;
        }
}

export const isPitcher = (positions) => {
    if(!positions) {
        return false;
    }
    
    if(positions.includes('SP') ||
        positions.includes('RP')) {
            return true;
        } else {
            return false;
        }
}

export const sortAndEnrichAllLevelPitcherStats = (allLevelStats) => {
    if(!allLevelStats) {
        return null;
    }

    if(Object.entries(allLevelStats).length <= 1) {
        return sortLevelsByPriority(allLevelStats);
    }

    let totalStats = {
        level: 'All',
        era: 0,
        whip: 0,
        inningsPitched: 0,
        gamesPlayed: 0,
        gamesStarted: 0,
        strikeOuts: 0,
        wins: 0,
        losses: 0,
        saves: 0,
        holds: 0,
        blownSaves: 0,
    };

    Object.values(allLevelStats).forEach(stats => {
        // Ensure stats are defined or default to 0
        const {
            era = 0,
            whip = 0,
            inningsPitched = 0,
            gamesPlayed = 0,
            gamesStarted = 0,
            strikeOuts = 0,
            wins = 0,
            losses = 0,
            saves = 0,
            holds = 0,
            blownSaves = 0
        } = stats;

        // Accumulate basic counting stats
        totalStats.gamesPlayed += gamesPlayed;
        totalStats.gamesStarted += gamesStarted;
        totalStats.strikeOuts += strikeOuts;
        totalStats.wins += wins;
        totalStats.losses += losses;
        totalStats.saves += saves;
        totalStats.holds += holds;
        totalStats.blownSaves += blownSaves;

        // Weighted sum for rate stats (avoid division by zero)
        if (inningsPitched > 0) {
            totalStats.era = ((totalStats.era * totalStats.inningsPitched) + (era * inningsPitched)) / (totalStats.inningsPitched + inningsPitched);
            totalStats.whip = ((totalStats.whip * totalStats.inningsPitched) + (whip * inningsPitched)) / (totalStats.inningsPitched + inningsPitched);
        }


        totalStats.inningsPitched += inningsPitched;
    });

    const enrichedStats = {
        ...allLevelStats,
        All: totalStats
    }

    return sortLevelsByPriority(enrichedStats);
}

export const sortAndEnrichAllLevelHitterStats = (allLevelStats) => {
    if(!allLevelStats) {
        return null;
    }

    if(Object.entries(allLevelStats).length <= 1) {
        return sortLevelsByPriority(allLevelStats);
    }

    let totalStats = {
        level: 'All',
        gamesPlayed: 0,
        runs: 0,
        homeRuns: 0,
        strikeOuts: 0,
        baseOnBalls: 0,
        avg: 0,
        atBats: 0,
        obp: 0,
        slg: 0,
        ops: 0,
        caughtStealing: 0,
        stolenBases: 0,
        plateAppearances: 0,
        rbi: 0
    };

    Object.values(allLevelStats).forEach(stats => {
        // Ensure stats are defined or default to 0
        const {
            gamesPlayed = 0,
            runs = 0,
            homeRuns = 0,
            strikeOuts = 0,
            baseOnBalls = 0,
            avg = 0,
            atBats = 0,
            obp = 0,
            slg = 0,
            caughtStealing = 0,
            stolenBases = 0,
            plateAppearances = 0,
            rbi = 0,
        } = stats;

        if (atBats > 0) {
            totalStats.avg = ((totalStats.avg * totalStats.atBats) + (avg * atBats)) / (totalStats.atBats + atBats);
            totalStats.slg = ((totalStats.slg * totalStats.atBats) + (slg * atBats)) / (totalStats.atBats + atBats);
        }
        if (plateAppearances > 0) {
            totalStats.obp = ((totalStats.obp * totalStats.plateAppearances) + (obp * plateAppearances)) / (totalStats.plateAppearances + plateAppearances);
        }

        totalStats.ops = totalStats.obp + totalStats.slg


        // Accumulate basic counting stats
        totalStats.gamesPlayed += gamesPlayed;
        totalStats.runs += runs;
        totalStats.homeRuns += homeRuns;
        totalStats.strikeOuts += strikeOuts;
        totalStats.baseOnBalls += baseOnBalls;
        totalStats.atBats += atBats;
        totalStats.caughtStealing += caughtStealing;
        totalStats.stolenBases += stolenBases;
        totalStats.plateAppearances += plateAppearances;
        totalStats.rbi += rbi;
    });

    const enrichedStats = {
        ...allLevelStats,
        All: totalStats
    }

    return sortLevelsByPriority(enrichedStats);
}

const sortLevelsByPriority = (entriesMap) => {
    if(!entriesMap) {
        return null;
    }

    const priority = {
        "ROOKIE_BALL": 7,
        "LOW_A": 6,
        "HIGH_A": 5,
        "AA": 4,
        "AAA": 3,
        "MLB": 2,
        "All": 1
    };
  
    const entriesArray = Object.entries(entriesMap).map(([key, value]) => (value));
  
    entriesArray.sort((a, b) => priority[a.level] - priority[b.level]);
  
    return entriesArray;
  }