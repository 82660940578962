import axios from 'axios';

// Use an environment variable for the API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'ngrok-skip-browser-warning': 'true'
  },
  withCredentials: true
});

export const generateFourSelection = (payload) => {
  return api.post('/hkb/generateFourSelection', payload);
};

export const generateTwoSelection = () => {
  return api.get('/hkb/generateTwoSelection');
};

export const rankFourPlayers = (payload) => {
  return api.post('/hkb/rankFourPlayers', payload);
};

export const rankTwoPlayers = (payload) => {
  return api.post('/hkb/rankTwoPlayers', payload);
};

export const getPlayerRankings = () => {
  return api.get('/hkb/playerRankings');
};

export const getPlayer = (payload) => {
  return api.post('/hkb/player', payload);
};

export const getPlayerWithValueHistory = (payload) => {
  return api.post('/hkb/player/valueHistory', payload);
};

export const getSelectionsCount = () => {
  return api.get('/hkb/selectionsCount');
};

export const getLast100Selections = () => {
  return api.get('/hkb/selectionHistory');
}

export const getLatest3SelectionsByPlayerId = (payload) => {
  return api.post('/hkb/player/selectionHistory', payload);
}

export const getJWARSeason = (payload) => {
  return api.post('/jwar/getSeason', payload);
};

export const getFantraxLeague = (payload) => {
  return api.post('/hkb/fantraxLeague', payload);
}

// Yahoo OAuth API endpoints
export const checkYahooAuthStatus = () => {
  return api.get('/hkb/yahoo/auth-status');
};

export const validateYahooLeague = (leagueId) => {
  return api.get(`/hkb/yahoo/validate-league/${leagueId}`);
};

export const getYahooLeague = (leagueId) => {
  return api.get(`/hkb/yahoo/league?leagueId=${encodeURIComponent(leagueId)}`);
};

export const getYahooAuthUrl = () => {
  return `${API_BASE_URL}/oauth2/authorization/yahoo`;
};