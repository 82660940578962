import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress
} from '@mui/material';
import { useFantasyLeague } from '../../../hooks/league/FantasyServiceContext';
import Title from '../../Title/Title';
import HeadWrapper from '../../HeadWrapper/HeadWrapper';
import PositionRankingsTable from './PositionRankingsTable';
import LeagueViewNavigation from '../LeagueViewNavigation';
import logo from '../../../assets/fullNameLogo.png';
import colors from '../../../config/colors';

const LeagueView = () => {
  const { leagueId } = useParams();
  const navigate = useNavigate();

  const {
    teams,
    farmTeams,
    winNowTeams,
    loading,
    error,
    setLeague,
  } = useFantasyLeague();

  const [view, setView] = useState('teams');

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  useEffect(() => {
    if (leagueId) {
      setLeague(leagueId);
    } else {
      // Redirect to input screen if no leagueId is provided
      navigate('/league');
    }
  }, [leagueId, setLeague, navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px',
        }}
      >
        <CircularProgress sx={{ color: colors.primary400 }} />
      </Box>
    );
  }

  if (teams.length === 0 && !loading) {
    // If we have a leagueId but no teams data, redirect to input
    navigate('/league');
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
        }}
      >
        <HeadWrapper
          title="Dynasty Baseball League Power Rankings"
          description="View real-time dynasty fantasy baseball power rankings for your league, based on crowdsourced player values."
          image={logo}
        />
        {error && (
          <Alert severity="error" sx={{ marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>
            Error retrieving league power rankings. Please check your internet connection or try again later.
          </Alert>
        )}
        <Title
          pageName="League Breakdown"
          extraText="View power rankings and roster breakdowns for your league! All rankings are calculated using our crowdsourced player and pick values."
          showPickerLink={true}
          showRankingsLink={false}
        />
        
        <LeagueViewNavigation leagueId={leagueId} />
        <Box sx={{ padding: 1 }}>
          <FormControl fullWidth sx={{ marginBottom: { xs: 1, sm: 2, md: 3 } }}>
            <InputLabel
              id="view-select-label"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
              }}
            >
              Rankings
            </InputLabel>
            <Select
              id="rankings-view-select"
              name="view"
              labelId="view-select-label"
              value={view}
              onChange={handleViewChange}
              label="Rankings"
              sx={{
                fontFamily: 'Avenir, Arial, sans-serif',
                fontWeight: '500',
                fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                '& .MuiSelect-select': {
                  padding: { xs: '8px 10px', sm: '10px 12px', md: '12.5px 14px' },
                },
              }}
            >
              <MenuItem
                value="teams"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: '500',
                  fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                  minHeight: { xs: '36px', sm: '42px', md: '48px' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>Full Roster</Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                    fontFamily: 'Avenir, Arial, sans-serif',
                  }}
                >
                  MLB ∙ MiLB ∙ Draft Capital
                </Typography>
              </MenuItem>
              <MenuItem
                value="farmTeams"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: '500',
                  fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                  minHeight: { xs: '36px', sm: '42px', md: '48px' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>Farm System</Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                    fontFamily: 'Avenir, Arial, sans-serif',
                  }}
                >
                  MiLB ∙ Draft Capital
                </Typography>
              </MenuItem>
              <MenuItem
                value="winNowTeams"
                sx={{
                  fontFamily: 'Avenir, Arial, sans-serif',
                  fontWeight: '500',
                  fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                  minHeight: { xs: '36px', sm: '42px', md: '48px' },
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>Starting Lineup</Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                    fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.8rem' },
                    fontFamily: 'Avenir, Arial, sans-serif',
                  }}
                >
                  MLB ∙ C, 1B, 2B, 3B, SS, 3 OF, 5 SP, 3 RP, 2 UT
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <PositionRankingsTable
            teams={view === 'teams' ? teams : view === 'farmTeams' ? farmTeams : winNowTeams}
            leagueId={leagueId}
            includePicks={view !== 'winNowTeams'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LeagueView;