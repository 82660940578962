import { useState, useEffect } from 'react';
import { generateFourSelection } from '../api/apiService';

const useFourPlayerSelection = () => {
  const [selectionPlayers, setSelectionPlayers] = useState(Array(4).fill(null));
  const [selectionError, setSelectionError] = useState(false);
  const [difficulty, setDifficulty] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshToggle, setRefreshToggle] = useState(false);

  const generateSelection = async () => {
    setIsLoading(true);
    try {
      const payload = { difficulty: difficulty + 1 };
      const response = await generateFourSelection(payload);
      setSelectionPlayers(response.data);
      setSelectionError(false);
      setDifficulty(prevDifficulty => Math.min(prevDifficulty + 1, 1000));
      setRefreshToggle(prev => !prev);
    } catch (error) {
      setSelectionError(true);
      console.error('There was an error fetching player selection options', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetDifficulty = () => {
    setDifficulty(0);
  };

  useEffect(() => {
    generateSelection();
  }, []);

  return {
    selectionPlayers, 
    selectionError, 
    generateSelection, 
    resetDifficulty,
    isLoading,
    difficulty,
    refreshToggle,
  };
};

export default useFourPlayerSelection;
