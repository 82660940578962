import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  Box,
  Grid
} from '@mui/material';
import { getPlayerWithValueHistory, getLatest3SelectionsByPlayerId } from '../../api/apiService';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Rankings.png';
import HorizontalSelectionList from './HorizontalSelectionList';
import ValueHistoryChart from './ValueHistoryChart';
import PlayerInfoCard from './PlayerInfoCard';
import StatsCard from './StatsCard';

const PlayerPage = ({ players }) => {
  const { id } = useParams();
  const [player, setPlayer] = useState(null);
  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [positionRanks, setPositionRanks] = useState(null);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const payload = { id };
    getPlayerWithValueHistory(payload)
      .then((response) => {
        setPlayer(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });

    getLatest3SelectionsByPlayerId(payload)
    .then((response) => {
      setSelections(response.data);
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
      setError(true);
    });
  }, [id]);


  useEffect(() => {
    const getPlayerById = (playerId) => {
      if(!players) {
        return null;
      }

      return players.find(player => player.id === playerId);
    };
  
    const foundPlayer = getPlayerById(id);
    if(foundPlayer) {
      setPositionRanks(foundPlayer.positionRanks);
    }
  }, [players, id]);

  if (!player || loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error" sx={{ marginTop: 2, marginBottom: 2, fontFamily: 'Avenir, Arial, sans-serif' }}>Error loading player data. Please check your internet connection or try again later.</Alert>
  }

  const { name, fullValueHistory, active } = player;

  // Transforming fullValueHistory (list) into the format for recharts
  const data = fullValueHistory.map((entry) => ({
    date: entry.eventDate,
    value: entry.value,
    rank: entry.rank,
  }));

  const earliestDate = data.length > 0 ? new Date(Math.min(...data.map((entry) => new Date(entry.date)))) : null;
  const formattedEarliestDate = earliestDate ? earliestDate.toISOString().split('T')[0] : null;

  return (
    <>
      <HeadWrapper 
        title={`${name} Player Page`} 
        description={`Dynasty baseball trends and insights for ${name}. View player value history since ${formattedEarliestDate}.`} 
        image={logo} 
      />
      <Box sx={{ p: 1, pb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* Player Info */}
            <PlayerInfoCard 
              player={player}
              positionRanks={positionRanks}
            />
          </Grid>

          {player.assetType === 'PLAYER' && player.playerInfo && (player.playerInfo.hitterStats || player.playerInfo.pitcherStats) && (
            <Grid item xs={12} md={6}>
              {/* Player Stats */}
              <StatsCard player={player} />
            </Grid>
          )}
        </Grid>

        {/* Value History */}
        <ValueHistoryChart
          player={player}
          data={data}
          formattedEarliestDate={formattedEarliestDate}
          positionRanks={positionRanks}
        />

        {/* Previous Selections */}
        <HorizontalSelectionList selections={selections} isSmallScreen={smallScreen} playerId={id} name={name} isActive={active} />
      </Box>
    </>
  );
};

export default PlayerPage;
