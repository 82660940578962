import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Card,
  CardContent,
  useTheme,
  ClickAwayListener,
  Tooltip
} from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MiniPlayerChart from './MiniPlayerChart';
import colors from '../../../config/colors';
import PositionBox from '../../PositionBox/PositionBox';
import { useFantasyLeague } from '../../../hooks/league/FantasyServiceContext';

const styles = {
  icon: {
    width: { xs: '22px', sm: '25px', md: '28px' },
    height: { xs: '22px', sm: '25px', md: '28px' },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
    p: { xs: 0.7, sm: 1 },
  },
  textSecondary: {
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    p: 1,
  },
};

const getRankSuffix = (rank) => {
  if (rank % 100 >= 11 && rank % 100 <= 13) {
    return 'th';
  }
  switch (rank % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const RosterList = ({ title, players, averageAge, rank }) => {
  const [showAllPlayers, setShowAllPlayers] = useState(false);
  const [openTooltips, setOpenTooltips] = useState({});
  const playersToDisplay = showAllPlayers ? players : players.slice(0, 5);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { teams } = useFantasyLeague();

  const rankSuffix = getRankSuffix(rank);

  const handleTooltipToggle = (playerId) => {
    setOpenTooltips((prev) => ({
      ...prev,
      [playerId]: !prev[playerId],
    }));
  };
  
  const handleTooltipClose = (playerId) => {
    setOpenTooltips((prev) => ({
      ...prev,
      [playerId]: false,
    }));
  };

  useEffect(() => {
    setShowAllPlayers(false);
  }, [players]);

  return (
    <Card
      sx={{ my: 2, border: `1px solid ${colors.secondary500}`, paddingBottom: 0 }}
    >
      <CardContent
        sx={{
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Typography
          component="div"
          sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            fontWeight: 'bold',
            paddingLeft: '6px',
            marginBottom: 2,
            fontSize: { xs: 18, sm: 22, md: 28 },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
          <Typography
            component="span"
            sx={{
              fontFamily: 'Avenir, Arial, sans-serif',
              fontWeight: 'normal',
              fontSize: { xs: 14, sm: 16, md: 18 },
              color: colors.grey500, // Adjust color as needed
              marginRight: { xs: '10px', sm: '12px', md: '14px' },
            }}
          >
            Ovr <span style={{ fontWeight: 'bold' }}>{rank}{rankSuffix}</span>
            <span
              style={{
                fontSize: '0.8em',
                color: colors.grey400,
              }}
            >
              /{teams.flat().length}
            </span>
          </Typography>
          <Typography
            component="span"
            sx={{
              fontFamily: 'Avenir, Arial, sans-serif',
              fontWeight: 'normal',
              fontSize: { xs: 14, sm: 16, md: 18 },
              color: colors.grey500, // Adjust color as needed
              marginRight: { xs: '10px', sm: '12px', md: '14px' },
            }}
          >
            Avg age <span style={{ fontWeight: 'bold' }}>{averageAge.toFixed(2)}</span>y
          </Typography>
        </Typography>
        <Grid
          container
          spacing={{ xs: 1.5, sm: 2 }}
          style={{ display: 'block' }}
        >
          {playersToDisplay.map((player, index) => (
            <Grid
              item
              xs={12}
              key={`${player.id}-${index}`}
              sx={{ display: 'flex', alignItems: 'stretch' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box sx={styles.icon}>{index + 1}</Box>
                <Paper
                  sx={{
                    ...styles.row,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Grid container>
                    <Grid item xs={5} sm={3.5} 
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            component={Link}
                            to={`/player/${player.id}`}
                            sx={{
                              ...styles.text,
                              marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                            }}
                          >
                            {player.name}
                          </Typography>
                          {(player.name.includes("(Batter)") || player.name.includes("(Pitcher)")) && (
                            <ClickAwayListener onClickAway={() => handleTooltipClose(player.id)}>
                              <Tooltip
                                title={
                                  <Typography
                                    sx={{ fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, fontFamily: 'Avenir, Arial, sans-serif' }}
                                  >
                                    Batter and pitcher specific assets are not crowdsourced in HKB and use estimated values
                                  </Typography>
                                }
                                placement="top"
                                open={openTooltips[player.id] || false}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                onClose={() => handleTooltipClose(player.id)}
                              >
                                <IconButton size="small" onClick={() => handleTooltipToggle(player.id)}>
                                  <InfoOutlinedIcon fontSize='inherit' />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          )}
                        </Box>
                    </Grid>
                    {!isSmallScreen && (<Grid item xs={3} sm={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        {player.assetType === 'PLAYER' && (
                          <Typography
                            sx={{
                              ...styles.textSecondary,
                              marginRight: 1,
                            }}
                          >
                            {player.age}y
                          </Typography>
                        )}
                      </Grid>)}
                    <Grid item xs={3.5}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {player.assetType === 'PLAYER' ? (
                        <Typography
                          sx={{
                            ...styles.textSecondary,
                            marginRight: 0,
                          }}
                        >
                          {player.positions && player.positions.map((position, index) => <PositionBox key={`${player.id}-${position}`} position={position} rank={player.positionRanks[position]} />)}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            ...styles.textSecondary,
                            marginRight: 1,
                          }}
                        >
                          <PositionBox key={index} position="PICK" />
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={3.5}
                      sm={3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <MiniPlayerChart valueHistory={player.valueHistory30Days} defaultValue={100} />
                      <Typography
                        sx={{
                          ...styles.text,
                          marginRight: 1,
                        }}
                      >
                        {player.value && player.value.toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
        {players.length > 5 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              py: 1,
            }}
          >
            <IconButton
              onClick={() => setShowAllPlayers(!showAllPlayers)}
              sx={{
                color: colors.primary400,
                '&:hover': { color: colors.primary300 },
              }}
            >
              {showAllPlayers ? (
                <KeyboardArrowUpIcon fontSize="large" />
              ) : (
                <KeyboardArrowDownIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              py: 1.5,
            }}
          ></Box>
        )}
      </CardContent>
    </Card>
  );
};

export default RosterList;
