import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { styled, keyframes } from '@mui/system';
import colors from '../../config/colors';

const styles = {
  icon: {
    minWidth: { xs: '15px', sm: '19px', md: '24px' },
    width: 'fit-content',
    padding: '0 1px',
    height: { xs: '17px', sm: '20px', md: '25px' },
    borderRadius: '20%',
    backgroundColor: colors.primary400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: { xs: '0.6rem', sm: '.75rem', md: '.85rem' },
    color: 'white',
    fontWeight: 600,
    flexShrink: 0,
    zIndex: 100,
    fontFamily: 'Avenir, Arial, sans-serif',
    position: 'relative',
  },
  row: {
    border: '1px solid',
    backgroundColor: colors.secondary100,
    borderColor: colors.secondary500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginLeft: { xs: '-10px', sm: '-12px', md: '-14px' },
    position: 'relative',
    zIndex: 99,
    width: '100%',
    minWidth: 0,
  },
  rowText: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.8rem', sm: '.9rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
    px: { xs: 0.7, sm: 1 },
    py: { xs: 0, sm: .5, md: 1},
  },
  text: {
    fontWeight: 600,
    color: 'black',
    fontSize: { xs: '0.8rem', sm: '.8rem', md: '1rem' },
    fontFamily: 'Avenir, Arial, sans-serif',
    maxWidth: '100%',
    px: { xs: 0.7, sm: 1 },
    py: { xs: 0, sm: .5, md: 1},
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'center',
  },
};

// Keyframes for the fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fadeOpacity' && prop !== 'delay',
})(({ delay }) => ({
  animation: `${fadeIn} 0.5s ease-out`,
  animationDelay: `${delay}s`,
  animationFillMode: 'forwards',
  opacity: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const PlayersToEvenTrade = ({ players, addTeamPlayer }) => {
  const [showPlayers, setShowPlayers] = useState(false);

  const primaryDelayBase = 0.2;

  const togglePlayersVisibility = () => {
    setShowPlayers((prev) => !prev);
  };

  return (
    <Box sx={{ mb: { xs: 0, sm: 0, md: 2}}}>
      <Box sx={styles.toggleButton}>
        <Button
          variant="text"
          color="primary"
          onClick={togglePlayersVisibility}
          sx={{
            fontFamily: 'Avenir, Arial, sans-serif',
            fontWeight: 600,
            fontSize: { xs: '0.7rem', sm: '.8rem', md: '.9rem' },
        }}
        >
          {showPlayers ? 'Hide suggestions' : 'Even this side?'}
        </Button>
      </Box>

      {showPlayers && (
        <Grid 
            container 
            spacing={{ xs: 1, sm: 1.5 }} 
            style={{ margin: '0 auto', width: '90%' }}
            justifyContent="center"
        >
          {players.map((player, index) => {
            const delay = index * primaryDelayBase; // Stagger animation if effectEnabled is true
            return (
            <Grid
              item
              xs={12}
              key={`${player.id}-${index}`}
              sx={{
                display: 'flex',
                alignItems: 'stretch',
              }}
            >
                <AnimatedContainer key={player.id} delay={delay}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Box sx={{ ...styles.icon, py: player.originalIndex + 1 > 999 ? '1px' : '0px' }}>{player.originalIndex + 1}</Box>
                        <Paper
                        sx={{
                            ...styles.row,
                            height: '100%',
                            width: '100%',
                        }}
                        >
                        <Grid container alignItems="center">
                            <Grid
                            item
                            xs={9}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                            <Typography
                                sx={{
                                ...styles.rowText,
                                marginLeft: { xs: '10px', sm: '12px', md: '14px' },
                                }}
                            >
                                {player.name}
                            </Typography>
                            </Grid>
                            <Grid
                            item
                            xs={2}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                            >
                            <Typography
                                sx={{
                                ...styles.text,
                                marginRight: 1,
                                }}
                            >
                                {player.value && player.value.toLocaleString()}
                            </Typography>
                            </Grid>
                            <Grid
                            item
                            xs={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            >
                            <IconButton
                                onClick={() => addTeamPlayer(player)}
                                sx={{
                                color: colors.secondary600,
                                cursor: 'pointer',
                                mr: 1,
                                py: .8,
                                }}
                            >
                                <AddCircleOutline
                                sx={{ fontSize: { xs: '1.1rem', sm: '1.3rem', md: '1.7rem' } }}
                                />
                            </IconButton>
                            </Grid>
                        </Grid>
                        </Paper>
                    </Box>
                </AnimatedContainer>
            </Grid>
          )}
          )}
        </Grid>
      )}
    </Box>
  );
};

export default PlayersToEvenTrade;