import React from 'react';
import { Container, Typography } from '@mui/material';
import Title from '../Title/Title';
import colors from '../../config/colors';

import HeadWrapper from '../HeadWrapper/HeadWrapper';

import logo from '../../assets/Slide1.png';

// Define a reusable style object for Typography components
const typographyStyles = {
  fontFamily: 'Avenir, Arial, sans-serif',
};

const ContactUs = () => {
  return (
    <>
      <HeadWrapper 
        title="Contact Us" 
        description="Contact methods for HarryKnowsBall." 
        image={logo} 
      />
      <Title pageName="Contact Us" showPickerLink={true} showRankingsLink={false} extraText="Don't be a stranger!" />
      <Container maxWidth="sm" sx={{ py: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }} sx={{ py: 1 }}>Email</Typography>
        <Typography sx={typographyStyles}>
          <span>- </span>
          <a href="mailto:harryknowsballteam@gmail.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: colors.primary400 }}>
            harryknowsballteam@gmail.com
          </a>
        </Typography>
        <Typography variant="h6" color={colors.primary400} style={{ fontFamily: 'Futura, sans-serif' }} sx={{ py: 1 }}>Socials</Typography>
        <Typography sx={{...typographyStyles }}>
          <span>- </span>
          <a href="https://twitter.com/HKBRankings" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: colors.primary400 }}>
            @HKBRankings
          </a>
          <span> on X</span>
        </Typography>
        
        <Typography sx={typographyStyles}>
          <span>- </span>
          <a href="https://bsky.app/profile/harryknowsball.bsky.social" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: colors.primary400 }}>
            @harryknowsball.bsky.social
          </a>
          <span> on Bluesky</span>
        </Typography>
      </Container>
    </>
  );
};

export default ContactUs;
