import { createContext, useContext, useState, useCallback } from 'react';
import { getFantraxLeague } from '../../api/apiService';

const FantraxLeagueContext = createContext(null);

export const LeagueProvider = ({ children }) => {
  const [teams, setTeams] = useState([]);
  const [farmTeams, setFarmTeams] = useState([]);
  const [winNowTeams, setWinNowTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [badRequestError, setBadRequestError] = useState(false);
  const [badRequestLeagueId, setBadRequestLeagueId] = useState('');
  const [leagueId, setLeagueId] = useState(null);
  const [averagePlayerValues, setAveragePlayerValues] = useState([]);
  const [unownedPlayers, setUnownedPlayers] = useState([]);
  const [leagueName, setLeagueName] = useState('');

  const getTeamById = useCallback(
    (teamId) => {
      // Flatten the groups to search across all teams
      const allTeams = teams.flat();
      return allTeams.find((team) => team.teamId === teamId) || null;
    },
    [teams]
  );

  const createGroupsByTopDrops = (sortedTeams, numGroups) => {
    const valueDrops = [];
    for (let i = 1; i < sortedTeams.length; i++) {
      const currentTeam = sortedTeams[i - 1];
      const nextTeam = sortedTeams[i];
      const valueDrop = currentTeam.entireRosterValues?.totalValue - nextTeam.entireRosterValues?.totalValue;
      valueDrops.push({ index: i, valueDrop });
    }
  
    const topDrops = valueDrops
      .sort((a, b) => b.valueDrop - a.valueDrop)
      .slice(0, numGroups - 1)
      .sort((a, b) => a.index - b.index);
  
    const groupedTeams = [];
    let start = 0;
    topDrops.forEach((drop) => {
      groupedTeams.push(sortedTeams.slice(start, drop.index));
      start = drop.index;
    });
    groupedTeams.push(sortedTeams.slice(start));
    return groupedTeams;
  };
  
  const calculateAveragePlayerValues = (teams) => {
    if (!teams.length) return [];

    const maxPlayers = Math.max(
      ...teams.map((team) => team.players?.length || 0)
    );

    const averages = Array(maxPlayers).fill(0);
    const counts = Array(maxPlayers).fill(0);

    teams.forEach((team) => {
      team.players?.forEach((player, index) => {
        if (player.value != null) {
          averages[index] += player.value;
          counts[index] += 1;
        }
      });
    });

    return averages.map((total, index) =>
      counts[index] > 0 ? Math.round(total / counts[index]) : 0
    );
  };

  const fetchLeagueData = useCallback(async (id) => {
    setLoading(true);
    setError(false);
    setBadRequestError(false);
  
    try {
      const payload = { leagueId: id };
      const response = await getFantraxLeague(payload);
  
      // Extract teams from the wrapper object
      const teamsWrapper = response.data;

      if(teamsWrapper) {
        setUnownedPlayers(teamsWrapper.unownedPlayers);
      }

      const processedData = (teamsWrapper.teams || []).map((team) => ({
        ...team,
        teamName: team.teamName?.replace(/(?<=\w)\?(?=\w)/g, "'") || team.teamName,
      }));
  
      const sortedTeams = processedData.sort(
        (a, b) => b.entireRosterValues?.totalValue - a.entireRosterValues?.totalValue
      );

      // Add overallRank to each team
      let rank = 1; // Start rank at 1
      let previousValue = null; // Track the previous value to handle ties

      sortedTeams.forEach((team, index) => {
        // If the current team's totalValue matches the previous one, assign the same rank
        if (team.entireRosterValues?.totalValue === previousValue) {
          team.entireRosterValues.overallRank = rank;
        } else {
          // Otherwise, update the rank to the current index + 1
          rank = index + 1;
          team.entireRosterValues.overallRank = rank;
        }

        // Update the previousValue for the next iteration
        previousValue = team.entireRosterValues?.totalValue;
      });
  
      const numTiers = Math.ceil(sortedTeams.length / 4) + 1;
  
      const groupedTeams = createGroupsByTopDrops(sortedTeams, numTiers);
  
      const farmSortedTeams = processedData.sort(
        (a, b) => b.farmSystemValues?.totalValue - a.farmSystemValues?.totalValue
      );

      // Add overallRank to each team
      rank = 1; // Start with rank 1
      previousValue = null; // To handle ties in ranking

      farmSortedTeams.forEach((team, index) => {
        // If the current team's totalValue is the same as the previous one, it gets the same rank
        if (team.farmSystemValues?.totalValue === previousValue) {
          team.farmSystemValues.overallRank = rank;
        } else {
          // Otherwise, set the rank to the current index + 1
          rank = index + 1;
          team.farmSystemValues.overallRank = rank;
        }

        // Update the previousValue for the next iteration
        previousValue = team.farmSystemValues?.totalValue;
      });

      const farmGroupedTeams = createGroupsByTopDrops(farmSortedTeams, numTiers);
  
      const winNowSortedTeams = processedData.sort(
        (a, b) => b.startingLineup?.totalValue - a.startingLineup?.totalValue
      );

      // Add overallRank to each team
      rank = 1; // Start rank at 1
      previousValue = null; // Track the previous value to handle ties

      winNowSortedTeams.forEach((team, index) => {
        // If the current team's totalValue matches the previous one, assign the same rank
        if (team.startingLineup?.totalValue === previousValue) {
          team.startingLineup.overallRank = rank;
        } else {
          // Otherwise, update the rank to the current index + 1
          rank = index + 1;
          team.startingLineup.overallRank = rank;
        }

        // Update the previousValue for the next iteration
        previousValue = team.startingLineup?.totalValue;
      });

      const winNowGroupedTeams = createGroupsByTopDrops(winNowSortedTeams, numTiers);
  
      // Set both team groupings and rankings
      setTeams(
        groupedTeams.map((group) =>
          group.map((team) => ({
            ...team,
            rankings: team.entireRosterValues.rankings,
          }))
        )
      );
  
      setFarmTeams(
        farmGroupedTeams.map((group) =>
          group.map((team) => ({
            ...team,
            rankings: team.farmSystemValues.rankings,
          }))
        )
      );

      setWinNowTeams(
        winNowGroupedTeams.map((group) =>
          group.map((team) => ({
            ...team,
            rankings: team.startingLineup.rankings,
          }))
        )
      );
  
      setAveragePlayerValues(calculateAveragePlayerValues(processedData));
  
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
  
      if (error.response?.status === 400) {
        setBadRequestError(true);
        setBadRequestLeagueId(id);
      } else {
        setError(true);
      }
      return false;
    }
  }, []);
  

  const setLeague = useCallback(async (id) => {
    if (id === leagueId) {
      setError(false);
      setBadRequestError(false);
      // If the id is the same as the current leagueId, do nothing
      return true;
    }

    const success = await fetchLeagueData(id);
    if (success) {
      setLeagueId(id);
    }
    return success;
  }, [fetchLeagueData, leagueId]);

  const value = {
    teams,
    farmTeams,
    winNowTeams,
    averagePlayerValues,
    loading,
    error,
    badRequestError,
    badRequestLeagueId,
    leagueId,
    unownedPlayers,
    leagueName,
    getTeamById,
    setLeague,
  };

  return <FantraxLeagueContext.Provider value={value}>{children}</FantraxLeagueContext.Provider>;
};

export const useFantraxLeagueBreakdown = () => {
  const context = useContext(FantraxLeagueContext);
  if (!context) {
    throw new Error('useFantraxLeagueBreakdown must be used within a LeagueProvider');
  }
  return context;
};