import React, { createContext, useContext, useState, useCallback } from 'react';
import { LeagueProvider as YahooLeagueProvider, useYahooLeagueBreakdown } from './YahooLeagueContext';
import { LeagueProvider as FantraxLeagueProvider, useFantraxLeagueBreakdown } from './FantraxLeagueContext';

export const LEAGUE_SOURCES = {
  YAHOO: 'Yahoo',
  FANTRAX: 'Fantrax'
};

const FantasyServiceContext = createContext(null);

export const FantasyServiceProvider = ({ children }) => {
  const [activeService, setActiveService] = useState(LEAGUE_SOURCES.YAHOO);

  const value = {
    activeService,
    setActiveService
  };

  return (
    <FantasyServiceContext.Provider value={value}>
      <YahooLeagueProvider>
        <FantraxLeagueProvider>
          {children}
        </FantraxLeagueProvider>
      </YahooLeagueProvider>
    </FantasyServiceContext.Provider>
  );
};

export const useFantasyLeague = () => {
  const { activeService, setActiveService } = useContext(FantasyServiceContext);
  const yahooContext = useYahooLeagueBreakdown();
  const fantraxContext = useFantraxLeagueBreakdown();

  if (!activeService) {
    throw new Error('useFantasyLeague must be used within a FantasyServiceProvider');
  }

  const activeContext = activeService === LEAGUE_SOURCES.YAHOO 
    ? yahooContext 
    : fantraxContext;

  const setLeague = useCallback(
    async (leagueId, service = activeService) => {
      if (service !== activeService) {
        setActiveService(service);
      }

      const targetContext =
        service === LEAGUE_SOURCES.YAHOO ? yahooContext : fantraxContext;

      return targetContext.setLeague(leagueId);
    },
    [activeService, yahooContext, fantraxContext, setActiveService]
  );

  return {
    teams: activeContext.teams,
    farmTeams: activeContext.farmTeams,
    winNowTeams: activeContext.winNowTeams,
    averagePlayerValues: activeContext.averagePlayerValues,
    loading: activeContext.loading,
    error: activeContext.error,
    badRequestError: activeContext.badRequestError,
    badRequestLeagueId: activeContext.badRequestLeagueId,
    leagueId: activeContext.leagueId,
    unownedPlayers: activeContext.unownedPlayers,
    leagueName: activeContext.leagueName,
    getTeamById: activeContext.getTeamById,

    setLeague,

    activeService,
  };
};