import React from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
} from '@mui/material';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import ListIcon from '@mui/icons-material/List';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import { Link } from 'react-router-dom';
import colors from '../../config/colors';
import { useLocation } from 'react-router-dom';

const SidebarMobile = () => {

  const location = useLocation();

  const renderSection = (title, items) => (
    <Box sx={{ py: 1, px: 2 }}>
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          color: colors.primary400,
          fontFamily: 'Futura, sans-serif',
          textTransform: 'uppercase',
          my: 1,
        }}
      >
        {title}
      </Typography>
      <List disablePadding>
        {items.map(({ path, text, icon }) => (
          <ListItemButton
            key={text}
            component={Link}
            to={path}
            selected={location.pathname === path}
            sx={{
              borderRadius: 1,
              px: 2,
              py: 1,
              mb: 0.5,
              '&.Mui-selected': {
                backgroundColor: colors.secondary300,
                '&:hover': {
                  backgroundColor: colors.secondary300,
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: colors.grey500, minWidth: 36 }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: colors.grey700,
                    fontFamily: 'Futura, sans-serif',
                    fontSize: '0.95rem',
                  }}
                >
                  {text}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const sections = [
    {
      title: 'Dynasty Tools',
      items: [
        { path: '/rankings', text: 'Rankings', icon: <ListIcon /> },
        { path: '/calculator', text: 'Trade Calculator', icon: <ShuffleIcon /> },
        { path: '/league', text: 'League Breakdown', icon: <GroupIcon /> },
        { path: '/picker', text: 'Picker', icon: <SwipeVerticalIcon /> },
        { path: '/activity', text: 'Activity Feed', icon: <DynamicFeedIcon /> },
      ],
    },
    {
      title: 'General',
      items: [
        { path: '/faq', text: 'FAQ', icon: <InfoIcon /> },
        { path: '/contact', text: 'Contact Us', icon: <EmailIcon /> },
      ],
    },
  ];

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
          boxSizing: 'border-box',
          marginTop: '76px',
          borderRight: 'none',
          backgroundColor: '#fafafa',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Divider />
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          {renderSection(section.title, section.items)}
          <Divider sx={{ mt: 1 }} />
        </React.Fragment>
      ))}
    </Drawer>
  );
};

export default SidebarMobile;
