import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Title from '../../Title/Title';
import HeadWrapper from '../../HeadWrapper/HeadWrapper';
import LeagueViewNavigation from '../LeagueViewNavigation';
import logo from '../../../assets/fullNameLogo.png';
import RosterList from './RosterList';
import PlayerValuesChart from './PlayerValuesChart';
import StartingLineupList from './StartingLineupList';
import { useFantasyLeague } from '../../../hooks/league/FantasyServiceContext';

const TeamView = () => {
  const { leagueId, teamId } = useParams();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [team, setTeam] = useState(null);
  const [farmPlayers, setFarmPlayers] = useState([]);
  const [averageProspectAge, setAverageProspectAge] = useState('');

  const { teams, loading, getTeamById, setLeague, averagePlayerValues, activeService } = useFantasyLeague();

  useEffect(() => {
    const initializeLeague = async () => {
      if (teams.length === 0) {
        const success = await setLeague(leagueId);
        if (!success) {
          navigate(`/league/${leagueId}/rankings`, { replace: true });
          return;
        }
      }
      setInitialized(true);
    };

    initializeLeague();
  }, [leagueId, setLeague, teams.length, navigate]);

  useEffect(() => {
    if (initialized && !loading) {
      const selectedTeam = getTeamById(teamId);
      const lastTeamId = localStorage.getItem(`lastTeamId_${leagueId}`); // Retrieve the last teamId
  
      if (!teams) {
        navigate(`/league/${leagueId}/rankings`, { replace: true });
      } else if (!selectedTeam) {
        const allTeamIds = teams.flat().map(team => team.teamId);
        const fallbackTeamId = lastTeamId && allTeamIds.includes(lastTeamId) 
          ? lastTeamId 
          : allTeamIds[0];
        navigate(`/league/${leagueId}/team/${fallbackTeamId}`, { replace: true });
      } else {
        setTeam(selectedTeam); 
        localStorage.setItem(`lastTeamId_${leagueId}`, teamId); // Save the current teamId
  
        const filteredFarmPlayers = selectedTeam.players
          .filter((player) => player.assetType === "PLAYER")
          .filter((player) => !player.majorLeaguer);
  
        setFarmPlayers(filteredFarmPlayers);
        setAverageProspectAge(
          filteredFarmPlayers.length > 0
            ? filteredFarmPlayers.reduce((sum, player) => sum + player.age, 0) /
              filteredFarmPlayers.length
            : 0
        );
      }
    }
  }, [initialized, loading, teamId, getTeamById, leagueId, navigate, teams]);

  const handleTeamChange = (event) => {
    navigate(`/league/${leagueId}/team/${event.target.value}`);
  };

  if (!team) {
    return null; // Navigation will happen in useEffect
  }

  const getDescription = () => {
    return `Enter your ${activeService} League ID and see real-time dynasty fantasy baseball power rankings, roster breakdowns, and available players based on crowdsourced player values.`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
        }}
      >
        <HeadWrapper
          title="Power Rankings Team Page"
          description={getDescription()}
          image={logo}
        />
        <Title
          pageName="League Breakdown"
          extraText="View power rankings and roster breakdowns for your league! All rankings are calculated using our crowdsourced player and pick values."
          showPickerLink={true}
          showRankingsLink={false}
        />
        <LeagueViewNavigation leagueId={leagueId} />
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '200px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ padding: { xs: .5, sm: 1 }, margin: '0 auto' }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderBottom: '1px solid #ddd',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ marginBottom: 1}}>
                  <InputLabel
                    id="team-select-label"
                    sx={{
                      fontFamily: 'Avenir, Arial, sans-serif',
                      fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                    }}
                  >
                    Team
                  </InputLabel>
                  <Select
                    id="teams-view-select"
                    name="teams"
                    labelId="team-select-label"
                    value={teamId || teams.flat()[0]?.teamId || ''}
                    onChange={handleTeamChange}
                    displayEmpty
                    variant="outlined"
                    fullWidth
                    label="Team"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '50vh', // Limit the dropdown height
                          overflowY: 'auto', // Enable scrolling
                        },
                      },
                    }}
                    sx={{
                      fontFamily: 'Avenir, Arial, sans-serif',
                      fontWeight: 600,
                      fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                    }}
                  >
                    {teams.flat().map((team) => (
                      <MenuItem
                        key={team.teamId}
                        value={team.teamId}
                        sx={{
                          fontFamily: 'Avenir, Arial, sans-serif',
                          fontSize: { xs: '.8rem', sm: '.95rem', md: '1.05rem' },
                          minHeight: '38px',
                          fontWeight: 500,
                        }}
                      >
                        {team.teamName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <RosterList
              title="Roster"
              players={team.players}
              averageAge={team.averageAge}
              rank={team.entireRosterValues.overallRank}
            />
            {farmPlayers && farmPlayers.length > 0 && (
              <RosterList
                title="Farm System"
                players={farmPlayers}
                averageAge={averageProspectAge}
                rank={team.farmSystemValues.overallRank}
              />
            )}
            <StartingLineupList startingLineup={team.startingLineup} rank={team.startingLineup.overallRank} />
            <PlayerValuesChart averagePlayerValues={averagePlayerValues} team={team} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TeamView;
