import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ChangeMetric = ({ value, label }) => {
  const renderChangeIcon = (value) => {
    if (value === 0 || value === '–') return null;

    const iconProps = {
      sx: { fontSize: { xs: '16px', sm: '18px', md: '20px' }, color: 'white', fontWeight: 'bold' },
    };

    const isNegative = value < 0;
    const bubbleColor = isNegative ? 'error.main' : 'success.main';

    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: bubbleColor,
          borderRadius: '50%',
          width: { xs: 18, sm: 21, md: 24 },
          height: { xs: 18, sm: 21, md: 24 },
          ml: 1
        }}
      >
        {isNegative ? <ArrowDownwardIcon {...iconProps} /> : <ArrowUpwardIcon {...iconProps} />}
      </Box>
    );
  };

  return (
      <Box sx={{ pr: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontWeight: 600, fontSize: { xs: '1.1rem', sm: '1.2rem', md: '1.3rem' } }}>
            {value.toLocaleString()}
          </Typography>
          {renderChangeIcon(value)}
        </Box>
        <Typography
          variant="subtitle1"
          sx={{ fontFamily: 'Avenir, Arial, sans-serif', fontSize: { xs: '.7rem', sm: '.8rem', md: '.9rem' }, color: 'text.secondary' }}
          gutterBottom
        >
          {label}
        </Typography>
      </Box>
  );
};

const QuickInsights = ({ valueHistoryData, value, rank, timeFrame }) => {
  const [valueChange, setValueChange] = useState(0);
  const [rankChange, setRankChange] = useState(0);

  const getRecentChange = useCallback((days) => {
    let recentEntries;
  
    if (days === null || days === undefined) {
      recentEntries = valueHistoryData;
    } else {
      const recentDate = new Date();
      recentDate.setDate(recentDate.getDate() - days);
      recentEntries = valueHistoryData.filter(entry => new Date(entry.date) >= recentDate);
    }
  
    const initialEntry = recentEntries[0] || { value, rank };
    const latestEntry = recentEntries[recentEntries.length - 1] || initialEntry;
  
    return {
      valueChange: latestEntry.value - initialEntry.value,
      rankChange: initialEntry.rank - latestEntry.rank,
    };
  }, [valueHistoryData, value, rank]);

  useEffect(() => {
    const { valueChange: newValueChange, rankChange: newRankChange } = getRecentChange(timeFrame);
    setValueChange(newValueChange);
    setRankChange(newRankChange);
  }, [timeFrame, getRecentChange]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: { xs: .5, sm: .7, md: 1 } }}>
      <ChangeMetric value={valueChange} label="Value Change" />
      <Typography
          variant="body2"
          sx={{ borderRight: 1, borderColor: 'grey.300', mx: 1, height: '36px' }}
        >
        </Typography>
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, ml: 1 }}>
        <ChangeMetric value={rankChange} label="Rank Change" />
      </Box>
    </Box>
  );
};

export default QuickInsights;