import React from 'react';
import { AppBar, Box, Typography } from '@mui/material';
import colors from '../../config/colors';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

const SelectionCounter = ({ selectionsCount, error, topMargin = '0px', leftMargin = '0px' }) => {

    return (
        <AppBar position="fixed" color="inherit" elevation={0} sx={{
            top: topMargin,
            margin: 'auto',
            background: colors.primary100, // Light grey background
            borderBottom: '1px solid #e0e0e0',
            flexGrow: 1,
            width: '100%',
            minHeight: '16px',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%', // Ensuring it fills the AppBar height
                alignItems: 'center', // Vertically centering the text
                padding: '6px',
                marginLeft: leftMargin
            }}>
                <Odometer value={selectionsCount} format="(,ddd)" theme="default" style={{ fontFamily: 'Futura, Arial, sans-serif', fontSize: '.8rem', color: 'black' }} />
                <Typography variant="body2" component="span" sx={{ marginLeft: '4px', marginBottom: '0px', fontFamily: 'Futura, Arial, sans-serif', fontSize: '.8rem', color: 'black' }}>
                    player selections and counting!
                </Typography>
            </Box>
        </AppBar>
    );
};

export default SelectionCounter;
