import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import SidebarMobile from '../../components/Sidebar/SidebarMobile';
import TopbarMobile from '../../components/Topbar/TopbarMobile';
import SelectionCounter from '../../components/SelectionCounter/SelectionCounter';
import BottomBar from '../../components/BottomBar/BottomBar';

const Layout = ({ children, selectionsCount, error }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width: 700px)');

  const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

  const layoutStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures the layout takes the full height of the viewport
    backgroundColor: 'white'
  };
  
  const topbarContainerStyle = {
    flexShrink: 0,
  };
  
  const mainContentStyle = {
    marginLeft: isMobile ? 0 : '240px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column', // Stack content vertically
  };
  
  const contentStyleDesktop = {
    flexGrow: 1,
    marginTop: '30px',
    padding: '30px',
  };
  
  const contentStyleMobile = {
    flexGrow: 1,
    marginTop: '110px',
    padding: '2px',
  };

  useEffect(() => {
    setSidebarVisible(false);
  }, [location]);

  return (
    <div style={layoutStyle}>
      {isMobile ? (
        <>
          <div style={topbarContainerStyle}>
            <TopbarMobile toggleSidebar={toggleSidebar} sidebarVisible={sidebarVisible} />
            <SelectionCounter selectionsCount={selectionsCount} error={error} topMargin="76px" />
          </div>
          <div style={mainContentStyle}>
            {sidebarVisible ? <SidebarMobile /> : null}
            <div style={contentStyleMobile}>{children}</div>
          </div>
          <BottomBar />
        </>
      ) : (
        <div style={mainContentStyle}>
          <Sidebar />
          <SelectionCounter style={{ flexGrow: 1 }} selectionsCount={selectionsCount} error={error} topMargin="0px" leftMargin="240px" />
          <div style={contentStyleDesktop}>{children}</div>
          <BottomBar />
        </div>
      )}
    </div>
  );
};

export default Layout;
